import React, {Component} from 'react';
import './ItemAddEditForm.css';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {genericHelper} from "../../../helpers/genericHelper";
import {localStorageHelper} from "../../../helpers/localStorageHelper";
import {withRouter} from "../../../hooks/withRouter";
import {TransitionUp} from "../../Transition/Transition";
import {apiService} from "../../../services/apiService";
import Item from "../../../models/item";
import {withSnackbar} from "../../../providers/SnackbarProvider";
import SnackbarTypeEnum from "../../../enums/sanckbarTypeEnum";
import DateTextField from "../../DateTextField/DateTextField";
import moment from "moment/moment";
import DateFormatEnum from "../../../enums/dateFormatEnum";
import MediaTypeEnum from "../../../enums/media-type-enum";
import MediaFilesGrid from "../../MediaFilesGrid/MediaFilesGrid";
import ItemMediaFile from "../../../models/item-media-file";
import {fileTypeHelper} from "../../../helpers/fileTypeHelper";
import {withDialog} from "../../../providers/DialogProvider";
import {withMediaFilePreviewDialog} from "../../../providers/MediaFilePreviewDialogProvider";


function getCleanState(mythPeriod) {
  return {
    mediaFileList: [],
    // mediaFilesToRemove: [],
    initialStartDate: null,
    initialEndDate: null,
    item: {
      titleEl: {
        value: '',
        valid: true,
        touched: false
      },
      titleEn: {
        value: '',
        valid: true,
        touched: false
      },
      descriptionEl: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEn: {
        value: '',
        valid: false,
        touched: false
      },
      placeEl: {
        value: '',
        valid: true,
        touched: false
      },
      placeEn: {
        value: '',
        valid: true,
        touched: false
      },
      startDate: {
        value: null,
        valid: false,
        touched: false
      },
      endDate: {
        value: null,
        valid: true,
        touched: false
      },
      startDateFormat: {
        value: DateFormatEnum.date.toString(),
        valid: true,
        touched: false
      },
      endDateFormat: {
        value: DateFormatEnum.date.toString(),
        valid: true,
        touched: false
      },
      period: {
        id: mythPeriod ? mythPeriod._id : '',
        value: mythPeriod ? (mythPeriod.title.el + ' (' + mythPeriod.title.en + ')') : '',
        valid: mythPeriod ? true : false,
        touched: false
      },
      category: {
        id: '',
        value: '',
        valid: false,
        touched: false
      },
      isMythology: {
        value: false,
        valid: true,
        touched: false
      }
    },
    loading: false,
    formIsValid: false,
    availableCategories: [
      {
        el: 'Γεγονός',
        en: 'Event'
      }, {
        el: 'Έκθεμα',
        en: 'Exhibit'
      }
    ],
    availablePeriods: {},
    categoryColor: 'black',
    periodColor: '#A6A6A6',
    isYearCritical: false,
    submitted: false,
    hasError: false,
    errorCode: 0
  };
}

class ItemAddEditForm extends Component {


  constructor(props) {
    super(props);
    this.state = getCleanState(null);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isOpen && !this.props.isEdit && prevProps.periods.length === 0 && this.props.periods.length > 0) {
      this.setState(getCleanState(this.props.periods[0]));
    } else if (this.props.isEdit && !prevProps.isEdit) {

      try {
        const item = await apiService.getItem(this.props.item);

        let startDate = null;
        let startYear = null;
        if (item.startDate != null) {
          startDate = moment.utc(item.startDate).toDate();
          startYear = moment.utc(startDate).year();
        }

        let endDate = null;
        if (item.endDate != null) {
          endDate = moment.utc(item.endDate).toDate();
        }

        let mediaFileList = [];
        if (item.mediaFiles && item.mediaFiles.length > 0) {
          mediaFileList = item.mediaFiles;
        }

        this.setState({
          mediaFileList: mediaFileList,
          // mediaFilesToRemove: [],
          initialStartDate: startDate,
          initialEndDate: endDate,
          item: {
            titleEl: {
              value: item.title.el,
              valid: mediaFileList.length === 0 || item.title.el.length > 0,
              touched: false
            },
            titleEn: {
              value: item.title.en,
              valid: mediaFileList.length === 0 || item.title.en.length > 0,
              touched: false
            },
            descriptionEl: {
              value: item.description.el,
              valid: item.description.el.length > 0,
              touched: false
            },
            descriptionEn: {
              value: item.description.en,
              valid: item.description.en.length > 0,
              touched: false
            },
            placeEl: {
              value: item.place.el,
              valid: item.place.el.length >= 0 && item.place.el.length <= 1000,
              touched: false
            },
            placeEn: {
              value: item.place.en,
              valid: item.place.en.length >= 0 && item.place.en.length <= 1000,
              touched: false
            },
            startDate: {
              value: startDate,
              valid: true,
              touched: false
            },
            endDate: {
              value: endDate,
              valid: true,
              touched: false
            },
            startDateFormat: {
              value: item.startDateFormat || DateFormatEnum.date.toString(),
              valid: true,
              touched: false
            },
            endDateFormat: {
              value: item.endDateFormat || DateFormatEnum.date.toString(),
              valid: true,
              touched: false
            },
            period: {
              id: item.period ? item.period._id : '',
              value: item.period ? item.period.title.el + ' (' + item.period.title.en + ')' : '',
              valid: true,
              touched: false
            },
            category: {
              id: item.category,
              value: this.state.availableCategories[item.category],
              valid: true,
              touched: false
            },
            isMythology: {
              value: item.startDate != null && startYear < -6000,
              valid: true,
              touched: false
            }
          }
        }, () => {
          this.checkFormValidity();
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  getPeriodFromYear(year) {
    for (let period of this.props.periods) {
      if (year >= period.startYear && year <= period.endYear) {
        return period;
      }
    }
    return '';
  }

  setAvailablePeriods(criticalYears, year) {
    for (let i = 0; i < criticalYears.length; i++) {
      if (year === criticalYears[i]) {
        return {
          ids: [this.props.periods[i]._id, this.props.periods[i + 1]._id],
          titles: [
            this.props.periods[i].title.el + ' (' + this.props.periods[i].title.en + ')',
            this.props.periods[i + 1].title.el + ' (' + this.props.periods[i + 1].title.en + ')'
          ]
        };
      }
    }
  }

  checkFormValidity() {
    let formIsValid = true;
    for (let itemElement in this.state.item) {
      formIsValid = formIsValid && this.state.item[itemElement].valid;
    }

    // also get period and category colors
    let newPeriodColor = '#A6A6A6';
    for (let period of this.props.periods) {
      if (this.state.item.period.id === period._id) {
        newPeriodColor = period.color;
        break;
      }
    }

    this.setState({
      formIsValid: formIsValid,
      categoryColor: genericHelper.getCategoryColorByEnum(this.state.item.category.id),
      periodColor: newPeriodColor
    });
  }

  isFormTouched() {
    let formIsTouched = false;
    for (let itemElement in this.state.item) {
      formIsTouched = formIsTouched || this.state.item[itemElement].touched;
    }
    return formIsTouched;
  }

  handleStartDateFormatChange = (event) => {
    let updatedItem = {...this.state.item};
    updatedItem.startDateFormat = event.target.value;
    this.setState({
      item: updatedItem
    });
  }

  handleEndDateFormatChange = (event) => {
    let updatedItem = {...this.state.item};
    updatedItem.endDateFormat = event.target.value;
    this.setState({
      item: updatedItem
    });
  }

  handleChange = (itemElement) => event => {
    let updatedItem = {...this.state.item};
    updatedItem[itemElement].touched = true;

    // check field validity
    if (itemElement.indexOf('isMythology') > -1) {
      updatedItem[itemElement].value = !updatedItem[itemElement].value;

      if (updatedItem[itemElement].value) {
        const min = 6001;
        const max = 7001;
        let mythologyYear = Math.floor(Math.random() * (max - min)) + min;
        updatedItem.startDate.value = new Date(mythologyYear, 0, 1)
        updatedItem.startDate.valid = true;

        const startPeriod = this.getPeriodFromYear(-6500);
        updatedItem.period.valid = true;
        updatedItem.period.id = startPeriod._id;
        updatedItem.period.value = startPeriod.title?.el + ' (' + startPeriod.title?.en + ')';
      } else {
        updatedItem.startDate.value = null;
        updatedItem.startDate.valid = false;

        updatedItem.period.valid = true;
        updatedItem.period.id = '';
        updatedItem.period.value = '';
      }

      updatedItem.endDate.value = null;
      updatedItem.endDate.valid = true;
    } else if (itemElement.indexOf('title') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = event.target.value.length > 0;


      // if (updatedItem.mediaFileList.length > 0) {
      //   updatedItem[itemElement].valid = updatedItem[itemElement].valid && updatedItem[itemElement].length > 0;
      // }
    } else if (itemElement.indexOf('DateFormat') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = true;
    } else if (itemElement.indexOf('description') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = event.target.value.length > 0;
    } else if (itemElement.indexOf('place') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = event.target.value.length >= 0 && event.target.value.length <= 1000;
    } else if (itemElement === 'category') {
      updatedItem[itemElement].id = this.state.availableCategories.indexOf(event.target.value);
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = updatedItem[itemElement].id > -1;

      // if (updatedItem.mediaFileList.length === 0 && this.state.availableCategories.indexOf(event.target.value) === 2) {
      //   // updatedItem.imageUrls.valid = false;
      // }
    } else if (itemElement.indexOf('Date') > -1) {
      updatedItem[itemElement].value = event;
    } else if (itemElement === 'period') {
      updatedItem[itemElement].value = event.target.value;
      const index = this.state.availablePeriods.titles.indexOf(event.target.value);
      updatedItem.period.valid = index !== -1;
      updatedItem.period.id = updatedItem.period.valid ? this.state.availablePeriods.ids[index] : '';
    }

    // check date validity
    if (itemElement.indexOf('Date') > -1 && itemElement.indexOf('Format') === -1) {
      let elementDate = 'startDate';
      if (itemElement.indexOf('end') > -1) {
        elementDate = 'endDate';
      }

      updatedItem[elementDate].valid = true;
      if (itemElement.indexOf('start') > -1) {
        updatedItem[elementDate].valid = updatedItem[elementDate].value !== '' && updatedItem[elementDate].value !== null;
      }

      //   check if start date is after end date
      if (updatedItem.startDate.value != null && updatedItem.endDate.value != null) {
        updatedItem.startDate.valid = updatedItem.startDate.value <= updatedItem.endDate.value;
        updatedItem.endDate.valid = updatedItem.startDate.value <= updatedItem.endDate.value;
      }
    }


    // check period validity
    if (itemElement.indexOf('start') > -1 && itemElement.indexOf('Format') === -1) {
      let startDate = updatedItem.startDate.value;

      updatedItem.period.touched = true;
      if (startDate == null || !updatedItem.startDate.valid) {
        updatedItem.period.id = '';
        updatedItem.period.value = '';
        updatedItem.period.valid = false;
      } else {
        const startYear = moment(startDate).year();

        // check for critical years
        let criticalYears = [];
        for (let i = 0; i < this.props.periods.length - 1; i++) {
          criticalYears.push(this.props.periods[i].endYear);
        }

        let isCritical = false;
        for (let critical of criticalYears) {
          if (startYear === critical) {
            isCritical = true;
            break;
          }
        }

        if (isCritical) {
          updatedItem.period.id = '';
          updatedItem.period.value = '';
          updatedItem.period.valid = false;

          this.setState({
            isYearCritical: this.state.item.endDate.value == null,
            availablePeriods: this.setAvailablePeriods(criticalYears, startYear)
          }, () => {
            if (this.state.item.endDate.value != null) {
              let endYear = moment(this.state.item.endDate.value).year();

              if (startYear === endYear) {
                this.setState({isYearCritical: true});
              } else {
                const endPeriod = this.getPeriodFromYear(endYear);
                updatedItem.period.valid = startYear < endYear && endPeriod !== '' && this.state.availablePeriods.ids[1] === endPeriod._id;
                updatedItem.period.value = updatedItem.period.valid ? endPeriod.title.el + ' (' + endPeriod.title.en + ')' : '';
                updatedItem.period.id = updatedItem.period.valid ? endPeriod._id : '';
              }
            }
          });
        } else {
          this.setState({
            isYearCritical: false,
            availablePeriods: {}
          }, () => {
            const startPeriod = this.getPeriodFromYear(startYear);
            updatedItem.period.valid = startPeriod !== '';
            updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
            updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';

            // check for matching end year period
            if (this.state.item.endDate.value != null) {
              let endYear = moment(this.state.item.endDate.value).year();

              const endPeriod = this.getPeriodFromYear(endYear);
              updatedItem.period.valid = endPeriod !== '' && startPeriod === endPeriod && startYear <= endYear;
              updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';
              updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
            }
          });
        }
      }
    } else if (itemElement.indexOf('end') > -1 && itemElement.indexOf('Format') === -1 && this.state.item.startDate.value != null) {
      let startYear = moment(updatedItem.startDate.value).year();

      const startPeriod = this.getPeriodFromYear(startYear);

      // check for matching start year period
      if (updatedItem.endDate.value != null) {
        let endYear = moment(updatedItem.endDate.value).year();
        const endPeriod = this.getPeriodFromYear(endYear);

        if (this.state.availablePeriods.ids) {
          if (endYear === startYear) {
            this.setState({isYearCritical: true});
          } else {
            this.setState({
              isYearCritical: false
            }, () => {
              updatedItem.period.valid = startYear < endYear && endPeriod !== '' && this.state.availablePeriods.ids[1] === endPeriod._id;
              updatedItem.period.value = updatedItem.period.valid ? endPeriod.title.el + ' (' + endPeriod.title.en + ')' : '';
              updatedItem.period.id = updatedItem.period.valid ? endPeriod._id : '';
            });
          }
        } else {
          updatedItem.period.valid = startPeriod !== '' && startPeriod === endPeriod && startYear <= endYear;
          updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';
          updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
        }
      } else {
        this.setState({
          isYearCritical: this.state.availablePeriods.ids !== undefined
        }, () => {
          if (!this.state.isYearCritical) {
            updatedItem.period.value = startPeriod.title.el + ' (' + startPeriod.title.en + ')';
            updatedItem.period.valid = true;
          }
        });
      }
    }

    this.setState({
      item: updatedItem
    }, () => {
      this.checkFormValidity();
    });
  };

  submitForm = async (event) => {
    event.preventDefault();
    this.setState({loading: true});

    let startDate = this.state.item.startDate.value;
    let endDate = this.state.item.endDate.value;

    const item = new Item();

    item.title.el = this.state.item.titleEl.value;
    item.title.en = this.state.item.titleEn.value;
    item.description.el = this.state.item.descriptionEl.value;
    item.description.en = this.state.item.descriptionEn.value;
    item.place.el = this.state.item.placeEl.value;
    item.place.en = this.state.item.placeEn.value;
    item.startDate = startDate;
    item.endDate = endDate;
    item.startDateFormat = this.state.item.startDateFormat.value;
    item.endDateFormat = this.state.item.endDateFormat.value;
    item.period = this.state.item.period.id;
    item.category = this.state.item.category.id;

    if (this.props.isEdit) {
      item._id = this.props.item;
    }

    try {

      let savedItem = null;

      if (this.props.isEdit) {
        savedItem = await apiService.updateItem(item);
      } else {
        savedItem = await apiService.createItem(item);
      }

      for (let mediaFile of this.state.mediaFileList) {
        if (mediaFile.isNew) {
          await apiService.createItemMediaFile(savedItem._id, mediaFile);
        } else {
          await apiService.updateItemMediaFile(mediaFile);
        }
      }

      // for (let mediaFileToRemove of this.state.mediaFilesToRemove) {
      //   try {
      //     await apiService.deleteItemMediaFile(mediaFileToRemove);
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }

      this.setState({
        submitted: true
      }, () => {

        this.props.showSnackbar({
          message: 'Επιτυχής αποθήκευση!',
          snackbarType: SnackbarTypeEnum.SUCCESS
        });

        this.handleClose();
      });
    } catch (error) {

      this.props.showSnackbar({
        message: 'Κάτι πήγε στραβά, προσπαθήστε ξανά!',
        snackbarType: SnackbarTypeEnum.ERROR
      });

      this.setState({
        loading: false,
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorageHelper.clear();
            this.props.navigate('/login');
          } else {
            this.setState({
              submitted: true
            }, () => {
              // this.handleClose();
            });
          }
        }, 3000);
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }

    this.setState(getCleanState(this.props.periods[0]), () => {
      this.props.handleClose();
    });
  };


  addFiles = async (event) => {
    const files = event.target.files;
    if (files.length === 0)
      return;

    let newMediaFileList = [...this.state.mediaFileList];

    for (let i = 0; i < files.length; i++) {
      const mediaFile = new ItemMediaFile();
      mediaFile.file = files[i];
      mediaFile.isNew = true;
      mediaFile.mediaType = fileTypeHelper.getFileTypeValueByMimeType(files[i].type);
      mediaFile.order = newMediaFileList.filter(mf => mf.mediaType === mediaFile.mediaType).length;
      mediaFile._id = genericHelper.generateUUID();
      mediaFile.url = null;

      if (mediaFile.mediaType === MediaTypeEnum.imageMedia) {
        const reader = new FileReader();
        reader.onload = () => {
          mediaFile.imagePreview = reader.result;
          newMediaFileList.push(mediaFile);

          this.setState({
            mediaFileList: newMediaFileList,
          }, () => {
            this.forceUpdate(); // Forces a re-render and thus triggers componentDidUpdate
          });

        };
        reader.readAsDataURL(mediaFile.file);
      } else {
        newMediaFileList.push(mediaFile);
      }

    }

    this.setState({
      mediaFileList: newMediaFileList,
    });

  }

  previewFile = (mediaFile) => {
    this.props.showMediaFilePreviewDialog({
      mediaFile: mediaFile,
    });
  }

  deleteFile = async (mediaFileToDelete) => {
    const confirmed = await this.props.showDialog({
      title: 'Θέλετε να διαγράψετε το αρχείο;',
      confirmText: 'Διαγραφή',
      cancelText: 'Όχι',
    });

    if (!confirmed) {
      return;
    }

    // unfocus the delete button
    document.activeElement.blur();

    let currentMediaFileList = [...this.state.mediaFileList];
    if (mediaFileToDelete.isNew) {
      const newMediaFileList = currentMediaFileList.filter(mediaFile => mediaFile !== mediaFileToDelete);
      this.setState({
        mediaFileList: newMediaFileList,
      });
    } else {
      const newMediaFileList = currentMediaFileList.filter(mediaFile => mediaFile !== mediaFileToDelete);
      await apiService.deleteItemMediaFile(mediaFileToDelete._id);
      this.setState({
        mediaFileList: newMediaFileList,
      });
    }
  }

  switchMediaFilePlaces(mediaFile1, mediaFile2) {
    const mediaFileList = [...this.state.mediaFileList];
    const mediaFile1Index = mediaFileList.findIndex(mediaFile => mediaFile._id === mediaFile1._id);
    const mediaFile2Index = mediaFileList.findIndex(mediaFile => mediaFile._id === mediaFile2._id);

    mediaFileList[mediaFile1Index] = mediaFile2;
    mediaFileList[mediaFile2Index] = mediaFile1;

    mediaFileList.forEach((mediaFile, index) => {
      mediaFile.order = index;
    });

    this.setState({
      mediaFileList: mediaFileList,
    });
  }


  render() {
    return (
      <Dialog
        data-test={this.props.isOpen ? "item-add-edit-form-dialog" : ""}
        maxWidth={'xl'}
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={TransitionUp}
        keepMounted
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{paper: 'AdminDialogPaper'}}
      >
        <DialogContent>
          <div className="AdminDialogClose" onClick={this.handleClose}>
            <CloseIcon style={{fontSize: 30, color: '#595959'}}/>
          </div>

          <form className="ItemForm" onSubmit={this.preventDefaultSubmit}>
            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΤΙΤΛΟΣ</div>
              <FormControl className="TextField">
                <TextField
                  data-test="item-title-el"
                  label="ΕΛ"
                  value={this.state.item.titleEl.value}
                  error={this.state.item.titleEl.touched && !this.state.item.titleEl.valid}
                  onChange={this.handleChange('titleEl')}
                  margin="none"
                />
                {!this.state.item.titleEl.valid &&
                  <FormHelperText className="HelperText">
                    {(this.state.mediaFileList.length > 0 ? 'Ο τίτλος είναι υποχρεωτικός όταν υπάρχει εικόνα. ' : '')}
                  </FormHelperText>}
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  data-test="item-title-en"
                  label="ENG"
                  value={this.state.item.titleEn.value}
                  error={this.state.item.titleEn.touched && !this.state.item.titleEn.valid}
                  onChange={this.handleChange('titleEn')}
                  margin="none"
                />
                {!this.state.item.titleEn.valid &&
                  <FormHelperText className="HelperText">
                    {(this.state.mediaFileList.length > 0 ? 'Ο τίτλος είναι υποχρεωτικός όταν υπάρχει εικόνα. ' : '')}
                  </FormHelperText>}
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΠΕΡΙΓΡΑΦΗ</div>
              <FormControl className="TextField">
                <TextField
                  data-test="item-description-el"
                  label="ΕΛ"
                  value={this.state.item.descriptionEl.value}
                  error={this.state.item.descriptionEl.touched && !this.state.item.descriptionEl.valid}
                  onChange={this.handleChange('descriptionEl')}
                  margin="none"
                  required={true}
                  multiline
                  maxRows={3}
                />
                {this.state.item.descriptionEl.touched && !this.state.item.descriptionEl.valid &&
                  <FormHelperText className="HelperText">
                    Η περιγραφή είναι υποχρεωτική.
                  </FormHelperText>}
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  data-test="item-description-en"
                  label="ENG"
                  value={this.state.item.descriptionEn.value}
                  error={this.state.item.descriptionEn.touched && !this.state.item.descriptionEn.valid}
                  onChange={this.handleChange('descriptionEn')}
                  margin="none"
                  required={true}
                  multiline
                  maxRows={3}
                />
                {this.state.item.descriptionEn.touched && !this.state.item.descriptionEn.valid &&
                  <FormHelperText className="HelperText">
                    Η περιγραφή είναι υποχρεωτική.
                  </FormHelperText>
                }
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΚΑΤΗΓΟΡΙΑ *</div>
              <FormControl margin="none" className="TextField">
                <InputLabel id="category-select-label">*</InputLabel>
                <Select
                  data-test="item-category"
                  error={this.state.item.category.touched && !this.state.item.category.valid}
                  labelId="category-select-label"
                  label={'*'}
                  value={this.state.item.category.value}
                  onChange={this.handleChange('category')}
                  style={{color: this.state.categoryColor, fontSize: '110%'}}
                >
                  {this.state.availableCategories.map((category, index) => (
                    <MenuItem key={index} value={category}
                              data-test={'item-category-dropdown-option'}
                              style={{color: genericHelper.getCategoryColorByGrName(category.el), fontSize: '110%'}}>
                      {category.el} ({category.en})
                    </MenuItem>
                  ))}
                </Select>
                {this.state.item.category.touched && !this.state.item.category.valid &&
                  <FormHelperText className="HelperText">
                    Η κατηγορία είναι υποχρεωτική.
                  </FormHelperText>
                }
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΤΟΠΟΘΕΣΙΑ</div>
              <FormControl className="TextField">
                <TextField
                  data-test="item-place-el"
                  label="ΕΛ"
                  value={this.state.item.placeEl.value}
                  error={this.state.item.placeEl.touched && !this.state.item.placeEl.valid}
                  onChange={this.handleChange('placeEl')}
                  margin="none"
                />
                {this.state.item.placeEl.touched && !this.state.item.placeEl.valid &&
                  <FormHelperText className="HelperText">
                    Εισάγετε εώς 1000 χαρακτήρες.
                  </FormHelperText>
                }
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  data-test="item-place-en"
                  label="ENG"
                  value={this.state.item.placeEn.value}
                  onChange={this.handleChange('placeEn')}
                  margin="none"
                />
                {this.state.item.placeEn.touched && !this.state.item.placeEn.valid &&
                  <FormHelperText className="HelperText">
                    Εισάγετε εώς 1000 χαρακτήρες.
                  </FormHelperText>}
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΕΤΗ</div>
              <div className={'TextField flex flex-wrap items-start'}>
                <div className={'w-full pl-2 pb-4'}>
                  <FormControlLabel
                    label="Μυθολογία"
                    control={
                      <Checkbox checked={this.state.item.isMythology.value}
                                onClick={this.handleChange('isMythology')}
                      />
                    }
                  />
                </div>

                {!this.state.item.isMythology.value &&
                  <div className="w-full flex items-start py-2">
                    <div className={'w-1/2 px-1'}>
                      <DateTextField
                        dataTest="item-start-date"
                        dateFormatEnum={this.state.item.startDateFormat.value}
                        label="Από"
                        hasError={this.state.item.startDate.touched && !this.state.item.startDate.valid}
                        initialValue={this.state.initialStartDate}
                        onChange={this.handleChange('startDate')}
                      >
                      </DateTextField>
                    </div>
                    <div className={'w-1/2 px-1'}>
                      <FormControl className={'w-full'}>
                        <InputLabel id="startDateFormat-select-label">Format</InputLabel>
                        <Select
                          labelId="startDateFormat-select-label"
                          label={'Format'}
                          id="startDateFormat-select"
                          error={this.state.item.startDateFormat.touched && !this.state.item.startDateFormat.valid}
                          value={this.state.item.startDateFormat.value}
                          onChange={this.handleChange('startDateFormat')}
                        >
                          <MenuItem value={DateFormatEnum.date.toString()}>Ημερομηνία</MenuItem>
                          <MenuItem value={DateFormatEnum.monthYear.toString()}>Μήνας - Έτος</MenuItem>
                          <MenuItem value={DateFormatEnum.year.toString()}>Έτος</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }

                {!this.state.item.isMythology.value &&
                  <div className="w-full flex items-start py-2">
                    <div className={'w-1/2 px-1'}>
                      <DateTextField
                        dataTest="item-end-date"
                        dateFormatEnum={this.state.item.endDateFormat.value}
                        label="Έως"
                        initialValue={this.state.initialEndDate}
                        onChange={this.handleChange('endDate')}
                      >
                      </DateTextField>
                    </div>
                    <div className={'w-1/2 px-1'}>
                      <FormControl className={'w-full'}>
                        <InputLabel id="endDateFormat-select-label">Format</InputLabel>
                        <Select
                          labelId="endDateFormat-select-label"
                          label={'Format'}
                          id="endDateFormat-select"
                          error={this.state.item.endDateFormat.touched && !this.state.item.endDateFormat.valid}
                          value={this.state.item.endDateFormat.value}
                          onChange={this.handleChange('endDateFormat')}
                        >
                          <MenuItem value={DateFormatEnum.date.toString()}>Ημερομηνία</MenuItem>
                          <MenuItem value={DateFormatEnum.monthYear.toString()}>Μήνας - Έτος</MenuItem>
                          <MenuItem value={DateFormatEnum.year.toString()}>Έτος</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΠΕΡΙΟΔΟΣ</div>
              {this.state.isYearCritical ?
                <FormControl margin="none" className="TextField">
                  <InputLabel htmlFor="criticalInput" required></InputLabel>
                  <Select
                    value={this.state.item.period.value}
                    onChange={this.handleChange('period')}
                    input={<Input labelwidth={40} id="criticalInput"/>}
                  >
                    {this.state.availablePeriods.titles.map((title, index) => (
                      <MenuItem key={index} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                :
                <FormControl className="TextField">
                  <TextField className="disabled"
                             disabled
                             label=""
                             value={this.state.item.period.value}
                             onChange={this.handleChange('period')}
                             margin="none"
                             required={true}
                  />
                  {this.state.item.period.touched && !this.state.item.period.valid &&
                    <FormHelperText className="HelperText">
                      Μη έγκυρη περίοδος
                    </FormHelperText>}
                </FormControl>
              }
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΑΡΧΕΙΑ</div>
              <div className="TextField">
                <div>
                  <MediaFilesGrid
                    mediaFileList={this.state.mediaFileList}
                    onAdd={this.addFiles}
                    onDelete={this.deleteFile}
                    onPreview={this.previewFile}
                    switchMediaFilePlaces={this.switchMediaFilePlaces.bind(this)}
                    isUploadInProgress={false}
                  ></MediaFilesGrid>
                </div>
              </div>
            </div>


            <div className="Wrapper">
              <Button
                data-test="item-save-button"
                type="button"
                variant="contained"
                color="primary"
                className="NoRadiusButton"
                disabled={this.state.loading || !this.state.formIsValid}
                onClick={this.submitForm}
              >
                Αποθηκευση
              </Button>
              {this.state.loading &&
                <CircularProgress size={24}
                                  color="primary"
                                  className="ButtonProgress"
                />
              }
            </div>
            {this.state.hasError && (
              this.state.errorCode === 401 ?
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
                :
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
            )}
          </form>
        </DialogContent>
      </Dialog>
    )
  }

  preventDefaultSubmit = (event) => {
    event.preventDefault();
  }
}

export default withMediaFilePreviewDialog(withSnackbar(withDialog(withRouter(ItemAddEditForm))));
