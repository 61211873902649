import React, {Component} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './App.css';
import sharedEnv from "./shared/sharedEnv";
import LoginPage from "./Pages/LoginPage/LoginPage";
import AdminPage from "./Pages/AdminPage/AdminPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage/ResetPasswordPage";
import TimelinePage from "./Pages/TimelinePage/TimelinePage";
import DialogProvider from "./providers/DialogProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import PlaygroundPage from "./Pages/PlaygroundPage/PlaygroundPage";
import MediaFilePreviewDialogProvider from "./providers/MediaFilePreviewDialogProvider";

const theme = createTheme({
  // indigo theme
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: 'black'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: 'white'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto'
    ]
  }
});

class App extends Component {

  updateFavicon() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = process.env.PUBLIC_URL + '/favicons/' + sharedEnv.faviconFileName;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  componentDidMount() {
    // call api or anything
    this.updateFavicon();
  }

  render() {
    return (
      <SnackbarProvider>
        <MediaFilePreviewDialogProvider>
          <DialogProvider>
            <ThemeProvider theme={theme}>
              <div className="App">
                <BrowserRouter basename="/">
                  <Routes>
                    <Route path="/admin" exact Component={AdminPage}/>
                    <Route path="/login" exact Component={LoginPage}/>
                    <Route path="/resetPassword/:token" exact
                           Component={ResetPasswordPage}/>
                    <Route path="/:lang/:start?/:end?/:id?" exact
                           Component={TimelinePage}/>
                    <Route path="playground" exact Component={PlaygroundPage}/>
                    <Route path="*" Component={TimelinePage}/>
                  </Routes>
                </BrowserRouter>
              </div>
            </ThemeProvider>
          </DialogProvider>
        </MediaFilePreviewDialogProvider>
      </SnackbarProvider>
    );
  }
}

export default App;
