import CategoryEnum from "../enums/categoryEnum";
import SnackbarTypeEnum from "../enums/sanckbarTypeEnum";
import moment from "moment/moment";
import DateFormatEnum from "../enums/dateFormatEnum";
import sharedEnv from "../shared/sharedEnv";

require('moment/locale/el');

moment.updateLocale('el', {
  eras: [
    {
      since: '0001-01-01',
      until: +Infinity,
      offset: 1,
      name: 'μετά Χριστόν',
      narrow: sharedEnv.hideYearSuffix ? '' : 'μ.Χ.',
      abbr: sharedEnv.hideYearSuffix ? '' : 'μ.Χ.'
    },
    {
      until: -Infinity,
      since: '0000-12-31',
      offset: 1,
      name: 'προ Χριστού',
      narrow: 'π.Χ.',
      abbr: 'π.Χ.'
    }
  ],
  months: [
    "Ιαν", "Φεβ", "Μαρ", "Απρ", "Μαϊ", "Ιουν", "Ιουλ", "Αυγ", "Σεπ", "Οκτ", "Νοε", "Δεκ"
  ]
});

class GenericHelper {
  getCategoryElNameByEnum(categoryEnum) {
    switch (categoryEnum) {
      case CategoryEnum.event:
        return 'Γεγονός';
      case CategoryEnum.exhibit:
        return 'Έκθεμα';
      default:
        return '';
    }
  }

  getCategoryEnNameByEnum(categoryEnum) {
    switch (categoryEnum) {
      case CategoryEnum.event:
        return 'Event';
      case CategoryEnum.exhibit:
        return 'Exhibit';
      default:
        return '';
    }
  }

  copyToClipboard(text) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    textField.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(textField.value);
    textField.remove();
  };

  createColor(color, opacity) {
    return color
  }

  getAllAvailableCategories() {
    return [CategoryEnum.event, CategoryEnum.exhibit]
  }

  getCategoryColorByEnum(categoryEnum) {
    switch (categoryEnum) {
      case CategoryEnum.event:
        return '#ff7c44';
      case CategoryEnum.exhibit:
        return '#00a99c';
      default:
        return 'black';
    }
  }

  getCategoryColorByGrName(categoryName) {
    switch (categoryName) {
      case 'Γεγονός':
        return '#ff7c44';
      case 'Έκθεμα':
        return '#00a99c';
      default:
        return 'black';
    }
  }

  wordCounter(str, min, max) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");

    const count = str.split(' ').length;
    return count >= min && count <= max;
  }

  getTextWithMaxLength(text, maxLength) {
    if (maxLength !== 0 && text.length > maxLength) {
      const limit = text.substr(0, maxLength).lastIndexOf(' ');
      return text.substr(0, limit) + ' ...';
    }
    return text;
  }

  desc(a, b, order, orderBy) {
    if (orderBy === 'startYear') {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    if (orderBy === 'period.title.el') {
      if (b.period?.title?.el < a.period?.title?.el) {
        return -1;
      }
      if (b.period?.title?.el > a.period?.title?.el) {
        return 1;
      }
      return 0;
    } else if (orderBy === 'category') {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      if (b[orderBy] === a[orderBy]) {
        if (order === 'desc') {
          return -this.desc(a, b, order, 'startYear');
        }
        return this.desc(a, b, order, 'startYear');
      }
      return 0;
    } else {
      if (b[orderBy]?.el < a[orderBy]?.el) {
        return -1;
      }
      if (b[orderBy]?.el > a[orderBy]?.el) {
        return 1;
      }
      return 0;
    }
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, order, orderBy) : (a, b) => -this.desc(a, b, order, orderBy);
  }

  getYear(start, end) {
    if (start < -6000) {
      return '';
    }

    let textToReturn = '';

    if (start) {
      if (start < 0) {
        textToReturn = (-1 * start) + ' π.Χ.';
      } else {
        textToReturn = start;
        if (!sharedEnv.hideYearSuffix) {
          textToReturn += ' μ.Χ.';
        }
      }
    }

    if (end) {
      if (end < 0) {
        textToReturn += ' - ' + (-1 * end) + ' π.Χ.';
      } else {
        textToReturn += ' - ' + end;
        if (!sharedEnv.hideYearSuffix) {
          textToReturn += ' μ.Χ.';
        }
      }
    }

    return textToReturn;
  };

  getItemYear(dateString) {
    if (!dateString) {
      return null;
    }
    const date = moment.utc(dateString);

    return date.year();
  };

  getYearFromDateString(dateString) {
    return this.getItemYear(dateString);
  };

  getGreekDateString(startDateString, endDateString, lang = 'el') {
    if (!startDateString) {
      return '';
    }

    const startDate = moment.utc(startDateString);

    if (startDate.year() < -6000) {
      return '';
    }

    let textToReturn = startDate.locale(lang).format('DD/MM/y N');

    if (endDateString) {
      const endDate = moment.utc(endDateString);
      textToReturn += ' - ' + endDate.locale(lang).format('DD/MM/y N');
    }

    return textToReturn;
  };

  getGreekItemDateString(item, lang = 'el', hideYearSuffix = false) {
    const startDateString = item.startDate;

    if (!startDateString) {
      return '';
    }

    const startDate = moment.utc(startDateString);

    if (startDate.year() < -6000) {
      return '';
    }
    const endDateString = item.endDate;
    const startDateFormat = this.getDateFormatFromDateFormatEnum(item.startDateFormat);
    const endDateFormat = this.getDateFormatFromDateFormatEnum(item.endDateFormat);

    let textToReturn = '';

    let startDateText = '';

    if (startDate.year() < 0 || !hideYearSuffix) {
      startDateText = startDate.locale(lang).format(startDateFormat + ' N');
    } else {
      startDateText = startDate.locale(lang).format(startDateFormat);
    }

    textToReturn = startDateText;

    if (endDateString) {
      const endDate = moment.utc(endDateString);

      let endDateText = '';

      if (endDate.year() < 0 || !hideYearSuffix) {
        endDateText = endDate.locale(lang).format(endDateFormat + ' N');
      } else {
        endDateText = endDate.locale(lang).format(endDateFormat);
      }

      textToReturn += ' - ' + endDateText;
    }

    return textToReturn;
  };

  getDateFromDateString(dateString) {
    if (!dateString) {
      return null;
    }

    return moment.utc(dateString).toDate();
  };

  getMonthNameFromDate(date, locale) {
    return moment(date).locale(locale).format('MMM');
  }

  getYearFromDate(date, locale, hideYearSuffix) {
    if (date.year() <= 0 || !hideYearSuffix) {
      return moment(date).locale(locale).format('y N');
    }

    return moment(date).locale(locale).format('y');
  }

  getDateFormatFromDateFormatEnum(dateFormatEnum) {
    switch (dateFormatEnum) {
      case DateFormatEnum.date.toString():
        return 'DD/MM/y';
      case DateFormatEnum.monthYear.toString():
        return 'MMM y';
      case DateFormatEnum.year.toString():
        return 'y';
      default:
        return 'DD/MM/y';
    }
  };

  getSnackbarBackgroundColor(snackbarType) {
    switch (snackbarType) {
      case SnackbarTypeEnum.SUCCESS:
        return '#4caf50';
      case SnackbarTypeEnum.ERROR:
        return '#f44336';
      case SnackbarTypeEnum.WARNING:
        return '#ff9800';
      default:
        return 'black';
    }
  }

  isEmptyOrNull(value) {
    return !value || value === '';
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}


export const genericHelper = new GenericHelper();
