import React, {Component} from 'react';
import './PeriodsTable.css';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {genericHelper} from "../../../helpers/genericHelper";
import Table from "@mui/material/Table";

class PeriodsTable extends Component {

  headers = [
    {id: 'title', label: 'Τίτλος'},
    {id: 'startYear', label: 'Έτος έναρξης'},
    {id: 'description', label: 'Περιγραφή'},
    {id: 'category', label: 'Κατηγορία'}
  ];

  state = {
    order: 'asc',
    orderBy: 'startYear',
    page: 0,
    rowsPerPage: 20,
  };


  render() {
    const periods = this.props.periods;
    const openPeriodEditDialog = this.props.openPeriodEditDialog;
    const openPeriodDeleteDialog = this.props.openPeriodDeleteDialog;

    return (
      <Table className="PeriodsTable" data-test={'periods-table'}>
        <TableHead>
          <TableRow>
            <TableCell className="AdminHead miniCol"></TableCell>
            <TableCell className="AdminHead miniCol">Χρώμα</TableCell>
            <TableCell className="AdminHead mediumCol">Τίτλος</TableCell>
            <TableCell className="AdminHead mediumCol">Έτη</TableCell>
            <TableCell className="AdminHead">Περιγραφή</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periods.map((period, index) => (
            <TableRow
              data-test={'period-row'}
              className="AdminPeriodRow"
              hover
              tabIndex={-1}
              key={period._id}
            >
              <TableCell className="miniCol">

                <div className={'flex'}>
                  <IconButton className="IconButton"
                              data-test={'period-delete-button'}
                              aria-label="Delete"
                              onClick={event => openPeriodDeleteDialog(period._id)}
                              style={{marginTop: '-3px'}} // marginBottom: '10%',
                  >
                    <img alt="delete" style={{maxWidth: '22px'}}
                         src={process.env.PUBLIC_URL + "/images/delete.svg"}/>
                  </IconButton>
                  <IconButton className="IconButton"
                              data-test={'period-edit-button'}
                              aria-label="Edit"
                              onClick={event => openPeriodEditDialog(period._id)}
                  >
                    <img alt="delete" style={{maxWidth: '20px'}}
                         src={process.env.PUBLIC_URL + "/images/edit.svg"}/>
                  </IconButton>
                </div>
              </TableCell>
              <TableCell className="miniCol color-cell">
                <div className="w-full h-full flex items-center">
                  <div className="w-10 h-10 mx-auto rounded-full" style={{backgroundColor: period.color}}></div>
                </div>
              </TableCell>
              <TableCell className="mediumCol title-cell">{period.title.el}</TableCell>
              <TableCell
                className="mediumCol year-cell">{genericHelper.getYear(period.startYear, period.endYear)}</TableCell>
              <TableCell>{genericHelper.getTextWithMaxLength(period.description.el, 170)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default PeriodsTable;
