import React, {Component} from 'react';
import {withSnackbar} from "../../providers/SnackbarProvider";
import {withRouter} from "../../hooks/withRouter";
import {apiService} from "../../services/apiService";
import './PlaygroundPage.css';
import MediaFileCarousel from "../../Controls/MediaFileCarousel/MediaFileCarousel";
import MediaFilePreview from "../../Controls/MediaFilePreview/MediaFilePreview";
import {withMediaFilePreviewDialog} from "../../providers/MediaFilePreviewDialogProvider";

class PlaygroundPage extends Component {

  itemId = '66f272c29c7b0a3fc196d1d9';

  getItemData = async () => {
    const item = await apiService.getItem(this.itemId);
    this.setState({
      mediaFileList: item.mediaFiles,
      selectedMediaFile: item.mediaFiles.length > 0 ? item.mediaFiles[0] : null,
    });
  };

  handleMediaFileSelected = (mediaFile) => {
    this.setState({
      selectedMediaFile: mediaFile,
    });
  };

  showMediaFilePreview = () => {
    this.props.showMediaFilePreviewDialog({
      mediaFile: this.state.selectedMediaFile,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      mediaFileList: [],
    };
  }

  render() {
    return (
      <div className={'bg-gray-50 h-screen w-screen'}>
        <div className={'bg-gray-50 flex p-8'}>
          <button className={'custom-button'} onClick={this.getItemData}>
            Get Item Data
          </button>
          <button className={'custom-button'} onClick={this.showMediaFilePreview}>
            Show Media File Preview Dialog
          </button>
          <div className={'grow'}></div>
        </div>
        <div className={'flex h-[500px] w-full'}>
          <MediaFilePreview
            mediaFile={this.state.selectedMediaFile}>
          </MediaFilePreview>
        </div>
        <div>
          <MediaFileCarousel
            mediaFileList={this.state.mediaFileList}
            onItemSelected={this.handleMediaFileSelected}
          ></MediaFileCarousel>
        </div>
      </div>
    )
  }
}

export default withSnackbar(withMediaFilePreviewDialog(withRouter(PlaygroundPage)));
