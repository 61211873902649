import MediaTypeEnum from "../enums/media-type-enum";

class FileTypeHelper {

  fileTypes = [
    {value: MediaTypeEnum.imageMedia, displayValue: 'Φωτογραφίες', mimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp']},
    {
      value: MediaTypeEnum.videoMedia,
      displayValue: 'Βίντεο',
      mimeTypes: ['video/mp4', 'video/quicktime', 'video/x-msvideo']
    },
    {value: MediaTypeEnum.audioMedia, displayValue: 'Ηχητικό', mimeTypes: ['audio/mpeg', 'audio/x-wav', 'audio/x-aiff']},
    {
      value: MediaTypeEnum.pdfMedia,
      displayValue: 'Αρχεία',
      mimeTypes: ['application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']
    },
    {value: MediaTypeEnum.media3D, displayValue: '3D', mimeTypes: ['*/*']},
  ];


  getFileTypeValueByMimeType(mimeType) {
    for (let i = 0; i < this.fileTypes.length; i++) {
      if (this.fileTypes[i].mimeTypes.indexOf(mimeType) > -1) {
        return this.fileTypes[i].value;
      }
    }
    return MediaTypeEnum.media3D;
  }
}

export const fileTypeHelper = new FileTypeHelper();
