import React, {Component} from 'react';
import './PeriodAddEditForm.css';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {localStorageHelper} from "../../../helpers/localStorageHelper";
import {withRouter} from "../../../hooks/withRouter";
import {TransitionDown} from "../../Transition/Transition";
import {apiService} from "../../../services/apiService";
import Period from "../../../models/period";
import {SketchPicker} from "react-color";
import SnackbarTypeEnum from "../../../enums/sanckbarTypeEnum";
import {withSnackbar} from "../../../providers/SnackbarProvider";
import DateTextField from "../../DateTextField/DateTextField";
import DateFormatEnum from "../../../enums/dateFormatEnum";
import {genericHelper} from "../../../helpers/genericHelper";


function getCleanState() {
  return {
    initialStartDate: null,
    initialEndDate: null,
    period: {
      titleEl: {
        value: '',
        valid: false,
        touched: false
      },
      titleEn: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEl: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEn: {
        value: '',
        valid: false,
        touched: false
      },
      color: {
        value: '#000000',
        valid: true,
        touched: false
      },
      startDate: {
        value: null,
        valid: false,
        touched: false
      },
      endDate: {
        value: null,
        valid: true,
        touched: false
      },
    },
    formIsValid: false,
    loading: false,
    hasError: false,
    errorCode: 0,
    displayColorPicker: false
  };
}

class PeriodAddEditForm extends Component {

  constructor(props) {
    super(props);
    this.state = getCleanState();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.period === prevProps.period || this.props.period === '') {
      return;
    }
    try {
      const period = await apiService.getPeriod(this.props.period);

      let startDate = null;
      if (period.startYear != null) {
        startDate = new Date(new Date(period.startYear, 5, 15).setFullYear(period.startYear));
      }

      let endDate = null;
      if (period.endYear != null) {
        endDate = new Date(new Date(period.endYear, 5, 15).setFullYear(period.endYear));
      }

      let newPeriod = {
        titleEl: {
          value: period.title.el,
          valid: period.title.el.length > 0,
          // valid: genericHelper.wordCounter(period.description.el, 100, 450),
          touched: false
        },
        titleEn: {
          value: period.title.en,
          valid: period.title.en.length > 0,
          // valid: genericHelper.wordCounter(period.description.en, 1, 450),
          touched: false
        },
        descriptionEl: {
          value: period.description.el,
          valid: period.description.el.length > 0,
          // valid: genericHelper.wordCounter(period.description.el, 100, 450),
          touched: false
        },
        descriptionEn: {
          value: period.description.en,
          valid: period.description.en.length > 0,
          // valid: genericHelper.wordCounter(period.description.en, 1, 450),
          touched: false
        },
        color: {
          value: period.color,
          valid: true,
          touched: false
        },
        startDate: {
          value: startDate,
          valid: true,
          touched: false
        },
        endDate: {
          value: endDate,
          valid: true,
          touched: false
        },
      };

      this.setState({
        initialStartDate: startDate,
        initialEndDate: endDate,
        period: newPeriod
      }, () => {
        this.checkFormValidity();
      });
    } catch (error) {
      console.log(error)
    }
  }

  checkFormValidity() {
    let formIsValid = true;
    for (let periodElement in this.state.period) {
      formIsValid = formIsValid && this.state.period[periodElement].valid;
    }

    this.setState({
      formIsValid: formIsValid
    });
  }

  isFormTouched() {
    let formIsTouched = false;
    for (let periodElement in this.state.period) {
      formIsTouched = formIsTouched || this.state.period[periodElement].touched;
    }
    return formIsTouched;
  }

  handleChange = (element) => event => {
    let updatedPeriod = {...this.state.period};
    updatedPeriod[element].touched = true;

    if (element.indexOf('description') > -1) {
      updatedPeriod[element].value = event.target.value;
      updatedPeriod[element].valid = event.target.value.length > 0;
    } else if (element.indexOf('title') > -1) {
      updatedPeriod[element].value = event.target.value;
      updatedPeriod[element].valid = event.target.value.length > 0;
    } else if (element.indexOf('color') > -1) {
      updatedPeriod[element].value = event.hex;
      updatedPeriod[element].valid = updatedPeriod[element].value.match(/^#[0-9A-F]{6}$/i) !== null;
    } else if (element.indexOf('Date') > -1) {
      updatedPeriod[element].value = event;
    }

    // check date validity
    if (element.indexOf('Date') > -1) {
      let elementDate = 'startDate';
      if (element.indexOf('end') > -1) {
        elementDate = 'endDate';
      }

      updatedPeriod[elementDate].valid = updatedPeriod[elementDate].value !== '' && updatedPeriod[elementDate].value !== null;

      //   check if start date is after end date
      if (updatedPeriod.startDate.value != null && updatedPeriod.endDate.value != null) {
        updatedPeriod.startDate.valid = updatedPeriod.startDate.value <= updatedPeriod.endDate.value;
        updatedPeriod.endDate.valid = updatedPeriod.startDate.value <= updatedPeriod.endDate.value;
      }
    }

    this.setState({
      period: updatedPeriod
    }, () => {
      this.checkFormValidity();
    });
  }

  submitForm = async (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const period = new Period();

    if (this.props.isEdit) {
      period._id = this.props.period;
    }

    let startYear = null;
    let endYear = null;

    if (this.state.period.startDate.value != null) {
      startYear = genericHelper.getYearFromDateString(this.state.period.startDate.value);
    }

    if (this.state.period.endDate.value != null) {
      endYear = genericHelper.getYearFromDateString(this.state.period.endDate.value);
    }

    period.description.el = this.state.period.descriptionEl.value;
    period.description.en = this.state.period.descriptionEn.value;
    period.title.el = this.state.period.titleEl.value;
    period.title.en = this.state.period.titleEn.value;
    period.color = this.state.period.color.value;
    period.startYear = startYear;
    period.endYear = endYear;

    try {
      if (this.props.isEdit) {
        await apiService.updatePeriod(period)
      } else {
        await apiService.createPeriod(period)
      }

      this.props.showSnackbar({
        message: 'Επιτυχής αποθήκευση!',
        snackbarType: SnackbarTypeEnum.SUCCESS
      });

      this.handleClose();
    } catch (error) {

      this.props.showSnackbar({
        message: 'Κάτι πήγε στραβά, προσπαθήστε ξανά!',
        snackbarType: SnackbarTypeEnum.ERROR
      });

      this.setState({
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorageHelper.clear();
            this.props.navigate('/login');
          } else {
            this.handleClose();
          }
        }, 3000);
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    this.setState(getCleanState(), () => {
      this.props.handleClose();
    });
  };

  toggleDisplayColorPicker = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }


  render() {

    return (
      <Dialog
        data-test={this.props.isOpen ? "period-add-edit-form-dialog" : ""}
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={TransitionDown}
        keepMounted
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{paper: 'AdminDialogPaper'}}
      >
        <DialogContent>
          <div className="AdminDialogClose" onClick={this.handleClose}>
            <CloseIcon style={{fontSize: 30, color: '#595959'}}/>
          </div>

          <form className="period-form" onSubmit={this.submitForm}>
            <div className="PeriodFormField pt-10">
              <div className="PeriodFormFieldName">ΤΙΤΛΟΣ</div>
              <FormControl className="TextField">
                <TextField
                  data-test={"period-title-el"}
                  label="ΕΛ *"
                  value={this.state.period.titleEl.value}
                  error={this.state.period.titleEl.touched && !this.state.period.titleEl.valid}
                  onChange={this.handleChange('titleEl')}
                  margin="none"
                />
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  data-test={"period-title-en"}
                  label="ENG *"
                  value={this.state.period.titleEn.value}
                  error={this.state.period.titleEn.touched && !this.state.period.titleEn.valid}
                  onChange={this.handleChange('titleEn')}
                  margin="none"
                />
              </FormControl>
            </div>

            <div className="PeriodFormField">
              <div className="PeriodFormFieldName">ΕΤΗ</div>
              <div className="TextField">
                <div className="YearField pr-1">
                  <DateTextField
                    dataTest="period-start-year"
                    dateFormatEnum={DateFormatEnum.year.toString()}
                    label="Από"
                    hasError={this.state.period.startDate.touched && !this.state.period.startDate.valid}
                    initialValue={this.state.initialStartDate}
                    onChange={this.handleChange('startDate')}
                  >
                  </DateTextField>
                </div>

                <div className="YearField pl-1">
                  <DateTextField
                    dataTest="period-end-year"
                    dateFormatEnum={DateFormatEnum.year.toString()}
                    label="Έως"
                    hasError={this.state.period.endDate.touched && !this.state.period.endDate.valid}
                    initialValue={this.state.initialEndDate}
                    onChange={this.handleChange('endDate')}
                  >
                  </DateTextField>
                </div>
              </div>
            </div>

            <div className="PeriodFormField">
              <div className="PeriodFormFieldName">ΠΕΡΙΓΡΑΦΗ</div>
              <FormControl className="TextField">
                <TextField
                  data-test={"period-description-el"}
                  label="ΕΛ"
                  value={this.state.period.descriptionEl.value}
                  error={this.state.period.descriptionEl.touched && !this.state.period.descriptionEl.valid}
                  onChange={this.handleChange('descriptionEl')}
                  margin="none"
                  required={true}
                  multiline
                  maxRows={5}
                />
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  data-test={"period-description-en"}
                  label="ENG"
                  value={this.state.period.descriptionEn.value}
                  error={this.state.period.descriptionEn.touched && !this.state.period.descriptionEn.valid}
                  onChange={this.handleChange('descriptionEn')}
                  margin="none"
                  required={true}
                  multiline
                  maxRows={5}
                />
              </FormControl>
            </div>
            <div className="PeriodFormField">
              <div className="PeriodFormFieldName text-center">
                Χρώμα
              </div>
              <div className="TextField flex flex-wrap">
                <div className="w-5/6 h-20 mx-auto mb-4 rounded-2xl"
                     style={{backgroundColor: this.state.period.color.value}}
                     onClick={this.toggleDisplayColorPicker}></div>
                {this.state.displayColorPicker &&
                  <SketchPicker
                    className="mx-auto pt-4"
                    disableAlpha={true}
                    color={this.state.period.color.value}
                    onChangeComplete={this.handleChange('color')}
                    width={'95%'}
                  />
                }
              </div>
            </div>

            <div className="Wrapper pt-4">
              <Button
                data-test={"period-save-button"}
                type="submit"
                variant="contained"
                color="primary"
                className="NoRadiusButton"
                disabled={this.state.loading || !this.state.formIsValid}
              >
                Αποθηκευση
              </Button>
              {this.state.loading &&
                <CircularProgress size={24}
                                  color="primary"
                                  className="ButtonProgress"
                />
              }
            </div>
            {this.state.hasError && (
              this.state.errorCode === 401 ?
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
                :
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
            )}
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withSnackbar(withRouter(PeriodAddEditForm));
