import SimpleBar from "simplebar-react";
import React, {Component} from "react";
import {genericHelper} from "../../helpers/genericHelper";
import sharedEnv from "../../shared/sharedEnv";

class PeriodComponent extends Component {

  onMouseEnter = (index, color) => {
    if (this.props.activePeriods.indexOf(index) > -1 && this.props.activePeriods.length === 1) {
      document.getElementById('period' + index).style.transform = 'scale(1)';
      document.getElementById('period' + index).style.height = '100%';
      document.getElementById('period' + index).style.marginTop = '0';
    } else if (this.props.activePeriods.indexOf(index) > -1 && this.props.activePeriods.length < 3) {
      document.getElementById('period' + index).style.transform = 'scale(1.05)';
      document.getElementById('period' + index).style.height = '112%';
      document.getElementById('period' + index).style.marginTop = '-18px';
    } else {
      document.getElementById('period' + index).style.backgroundColor = color || '#000000';
      document.getElementById('period' + index).style.transform = 'scale(1.2)';
      document.getElementById('period' + index).style.marginTop = '-12px';
    }
  };

  onMouseLeave = (index, color) => {
    if (this.props.activePeriods.indexOf(index) < 0) {
      document.getElementById('period' + index).style.backgroundColor = color || '#000000';
    }
    document.getElementById('period' + index).style.transform = 'scale(1)';
    document.getElementById('period' + index).style.height = '100%';
    document.getElementById('period' + index).style.marginTop = '0px';
  };


  getPeriodSymbols(year) {
    let symbolA = ' π.Χ.';
    let symbolB = ' μ.Χ.';
    if (this.props.currentLang === 'en') {
      symbolA = ' B.C.';
      symbolB = ' A.D.';
    }


    if (year < 0) {
      return symbolA;
    }
    if (year > 0) {
      if (sharedEnv.hideYearSuffix) {
        return '';
      }
      return symbolB;
    }
  }

  getPeriodYears(year) {
    if (year < 0) {
      return (-1 * year);
    }
    if (year > 0) {
      return year;
    }
  }

  removeAccents(txt, isPeriod) {
    if (isPeriod && !txt) {
      return null;
    }
    return txt.replace(/ά/g, 'α').replace(/Ά/g, 'Α')
      .replace(/έ/g, 'ε').replace(/Έ/g, 'Ε')
      .replace(/ή/g, 'η').replace(/Ή/g, 'Η')
      .replace(/ί/g, 'ι').replace(/Ί/g, 'Ι')
      .replace(/ό/g, 'ο').replace(/Ό/g, 'Ο')
      .replace(/ύ/g, 'υ').replace(/Ύ/g, 'Υ')
      .replace(/ώ/g, 'ω').replace(/Ώ/g, 'Ω');
  }

  render() {
    let index = this.props.index;
    let period = this.props.period;
    let activePeriodFocused = this.props.activePeriodFocused;
    let activePeriods = this.props.activePeriods;
    let currentLang = this.props.currentLang;
    let onClick = this.props.onClick;
    let focusPeriod = this.props.focusPeriod;

    let isMythology = period.startYear <= -6000;

    return (
      <div
        key={index}
        className={"period" + (activePeriods.indexOf(index) > -1 && activePeriods.length === 1 ? (activePeriodFocused ? " focused-period" : "") : "")}
        data-test={"period" + index}
        id={'period' + index}
        onMouseEnter={() => this.onMouseEnter(index, period.color)}
        onMouseLeave={() => this.onMouseLeave(index, period.color)}
        onClick={onClick(index)}
      >
        {activePeriods.indexOf(index) !== -1 ? (
            activePeriods.length === 1 ?
              <div className="active-period">
                <div className="active-period-info">
                  <div
                    className="active-period-name">{this.removeAccents(period.title[currentLang], true)}</div>
                  <div className="period-years active-period-years">
                    {!isMythology &&

                      <div className={'w-full flex h-full items-center justify-start'}>
                        <div className={'w-fit flex justify-start'}>
                          <div className={'text-center'}>
                            {this.getPeriodYears(period.startYear)}
                            <br/>
                            {this.getPeriodSymbols(period.startYear)}
                          </div>
                        </div>
                        <div className={'w-16 text-center'}>
                          -
                        </div>
                        <div className={'w-full flex justify-start'}>
                          <div className={'text-center'}>
                            {this.getPeriodYears(period.endYear)}
                            <br/>
                            {this.getPeriodSymbols(period.endYear)}
                          </div>
                        </div>
                      </div>

                    }
                  </div>
                </div>
                <div className="active-period-description">
                  {activePeriodFocused ?
                    <SimpleBar autoHide={false} style={{
                      width: '100%',
                      height: '80%',
                      paddingRight: '18px',
                      paddingBottom: '10px',
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word'
                    }}>
                      {period.description[currentLang]}
                    </SimpleBar>
                    :
                    <div className="active-period-description-non-focused">
                      {period.description[currentLang]}
                    </div>
                  }
                  <div className="active-period-btn">
                    {period.description[currentLang].length > 300 &&
                      <div className="active-period-btn-text" onClick={focusPeriod}>
                        {currentLang === 'en' ? (
                          activePeriodFocused ? 'SHOW LESS' : 'SHOW MORE'
                        ) : (
                          activePeriodFocused ? 'ΛΙΓΟΤΕΡΑ' : 'ΠΕΡΙΣΣΟΤΕΡΑ'
                        )}
                      </div>}
                  </div>
                </div>
              </div>
              :
              <div className="period-info">
                <div className="period-name"
                     data-test={"period-name" + index}>
                  {this.removeAccents(period.title[currentLang], true)}
                </div>
                <div className="period-years">
                  {!isMythology &&

                    <div className={'w-full flex h-full items-center justify-center'}>
                      <div className={'w-full flex justify-end'}>
                        <div className={'text-center'}>
                          {this.getPeriodYears(period.startYear)}
                          <br/>
                          {this.getPeriodSymbols(period.startYear)}
                        </div>
                      </div>
                      <div className={'w-10'}>
                        -
                      </div>
                      <div className={'w-full flex justify-start'}>
                        <div className={'text-center'}>
                          {this.getPeriodYears(period.endYear)}
                          <br/>
                          {this.getPeriodSymbols(period.endYear)}
                        </div>
                      </div>
                    </div>

                  }
                </div>
              </div>
          )
          :
          <div
            className={"period-transformed-years" + (isMythology ? " period-transformed-years-hide" : "")}>
            {this.getPeriodYears(period.startYear)} {this.getPeriodSymbols(period.startYear)} - {this.getPeriodYears(period.endYear)} {this.getPeriodSymbols(period.endYear)}
          </div>
        }
      </div>
    )
  }
}

export default PeriodComponent;
