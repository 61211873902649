import React, {Component} from 'react';
import SimpleBar from 'simplebar-react';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';

import LazyImage from '../LazyImage/LazyImage';
import ImageFull from '../ImageFull/ImageFull';
import './ItemDialog.css';
import sharedEnv from "../../shared/sharedEnv";
import {genericHelper} from "../../helpers/genericHelper";
import {TransitionDown} from "../Transition/Transition";
import MediaFileCarousel from "../MediaFileCarousel/MediaFileCarousel";
import MediaFilePreview from "../MediaFilePreview/MediaFilePreview";
import {mediaFileHelper} from "../../helpers/mediaFileHelper";
import {withMediaFilePreviewDialog} from "../../providers/MediaFilePreviewDialogProvider";

class ItemDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isImageOpen: false,
      selectedMediaFile: null,
      carouselMediaFileList: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.item !== this.props.item) {
      if (this.props.item.mediaFiles != null) {
        this.setState({
          selectedMediaFile: this.props.item.mediaFiles.length > 0 ? this.props.item.mediaFiles[0] : null,
          carouselMediaFileList: mediaFileHelper.getCarouselMediaFileList(this.props.item.mediaFiles),
        });
      }
    }
  }

  displayYear(start, end, lang) {
    return '20/11/2023 μ.Χ. - 24/02/2022 μ.Χ.';

    let symbolA = ' π.Χ.';
    let symbolB = ' μ.Χ.';
    if (lang === 'en') {
      symbolA = ' B.C.';
      symbolB = ' A.D.';
    }

    if (sharedEnv.hideYearSuffix) {
      // symbolA = '';
      symbolB = '';
    }

    if (end) {
      if (start < 0 && end >= 0) {
        return (-1 * start) + symbolA + '-' + end + symbolB;
      } else if (start < 0 && end < 0) {
        return (-1 * start) + '-' + (-1 * end) + symbolA;
      }
      return start + '-' + end + symbolB;
    }

    if (start < 0) {
      return (-1 * start) + symbolA;
    }
    return start + symbolB;
  }

  getColor(period) {
    if (period) {
      return 'rgba(' + period.color + ', 1)';
    }
    return '';
  }

  getTitle(period, lang) {
    if (period) {
      return period.title[lang];
    }
    return '';
  }

  handleImageOpen = () => {
    this.setState({
      isImageOpen: true
    });
  };

  handleImageClose = () => {
    this.setState({
      isImageOpen: false
    });
  };

  handleMediaFileSelected = (mediaFile) => {
    this.setState({
      selectedMediaFile: mediaFile,
    });
  };

  previewFile = (mediaFile) => {
    this.props.showMediaFilePreviewDialog({
      mediaFile: mediaFile,
    });
  }

  render() {
    const url = sharedEnv.frontURL + '/' + this.props.lang + '/' +
      this.props.windowStart + '/' + this.props.windowEnd + '/' + this.props.item._id;

    return (
      <Dialog
        classes={{paper: this.state.carouselMediaFileList && this.state.carouselMediaFileList.length > 0 ? 'dialog-paper' : 'dialog-paper-no-img'}}
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        TransitionComponent={TransitionDown}
        keepMounted
        maxWidth={'xl'}
        aria-labelledby="dialog"
        aria-describedby="dialog"
        style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
      >
        <div className="dialog-shadow flex">

          <div className={"h-full w-32 flex items-center " + (this.props.hasPrev ? 'cursor-pointer' : '')}
               onClick={this.props.hasPrev ? this.props.getPrev : null}>
            {this.props.hasPrev &&
              <div className="dialog-arrow-new left mx-auto"></div>
            }
          </div>

          <div className="dialog-shape grow">
            <div className="dialog-content flex">
              <div className="dialog-close" onClick={this.props.handleClose}>
                <img style={{width: '80%'}} alt="close" src={process.env.PUBLIC_URL + "/images/close-dialog.svg"}/>
              </div>

              {this.state.carouselMediaFileList && this.state.carouselMediaFileList.length > 0 &&
                <div className="relative w-[40%] h-full pl-32 py-4">
                  <div className="relative w-full h-full flex flex-col items-start content-start">
                    <div
                      className=" flex flex-wrap items-end w-5/6 h-[70%] transform -translate-y-[30%] -translate-x-1/2 absolute top-0 left-1/2">
                      <div className={'flex w-full h-full'}>
                        <MediaFilePreview
                          className="w-full h-full"
                          mediaFile={this.state.selectedMediaFile}>
                        </MediaFilePreview>
                      </div>
                      <div className="flex absolute -bottom-12 w-full">
                        {this.state.selectedMediaFile &&
                          <button className="mat-icon-button px-2 pt-2 mx-auto"
                                  onMouseDown={(event) => {
                                    event.preventDefault(); // Prevent drag behavior
                                  }}
                                  onClick={() => {
                                    this.previewFile(this.state.selectedMediaFile);
                                  }}>
                            <span className="text-open-lab-700 material-icons">open_in_new</span>
                          </button>
                        }
                      </div>
                    </div>


                    <div className="absolute bottom-2 left-0 w-full h-[25%] mx-auto pb-1">
                      {this.state.carouselMediaFileList && this.state.carouselMediaFileList.length > 0 &&
                        <MediaFileCarousel
                          mediaFileList={this.state.carouselMediaFileList}
                          onItemSelected={this.handleMediaFileSelected}
                        ></MediaFileCarousel>
                      }
                    </div>

                    <div className="category-color-new">
                      <div style={{height: '100%', width: '100%', background: '#000000'}}></div>
                    </div>
                    {/*<div className="category-color">*/}
                    {/*  <div style={{height: '100%', width: '50%', background: this.props.categoryColor}}></div>*/}
                    {/*  <div style={{*/}
                    {/*    height: '100%',*/}
                    {/*    width: '50%',*/}
                    {/*    background: this.props.categoryColor,*/}
                    {/*    opacity: '0.5'*/}
                    {/*  }}></div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              }

              {this.state.carouselMediaFileList && this.state.carouselMediaFileList.length > 0 &&
                <div className="w-[5%] h-full"></div>
              }

              <div
                className={"relative h-full py-4 " + (this.state.carouselMediaFileList && this.state.carouselMediaFileList.length > 0 ? 'w-[55%] pr-32' : 'w-full px-32')}>
                <div className="relative w-full h-full flex flex-col items-start content-start">
                  <div className="social-networks-container w-full py-6">
                    <div className="flex flex-wrap">
                      <div className="w-full text-xs opacity-60">
                        SHARE ON SOCIAL
                      </div>
                      <div
                        className={"py-4 social-networks social-networks-no-image"}>
                        <div className="social-network">
                          <TwitterShareButton
                            className="social-network-button"
                            url={url}
                            quote={sharedEnv.shareQuote}
                          >
                            <img alt="twitter" src={process.env.PUBLIC_URL + "/images/X_logo.svg"}/>
                          </TwitterShareButton>
                        </div>
                        <div className="social-network">
                          <FacebookShareButton
                            className="social-network-button"
                            url={url}
                            quote={sharedEnv.shareQuote}
                          >
                            <img alt="facebook" src={process.env.PUBLIC_URL + "/images/fb.svg"}/>
                          </FacebookShareButton>
                        </div>
                        <Tooltip className="social-network"
                                 title="Copy url"
                                 placement="right"
                        >
                          <div style={{cursor: 'pointer'}} onClick={
                            () => {
                              genericHelper.copyToClipboard(url);
                              this.props.openSnackbar();
                            }
                          }>
                            <img style={{width: '90%'}} alt="copy url"
                                 src={process.env.PUBLIC_URL + "/images/copy.svg"}/>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="w-full text-3xl pt-12">
                    {this.props.item._id !== -1 && this.props.item.title != null && this.props.item.title[this.props.lang]}
                  </div>

                  <div className="w-full text-lg pt-14 pb-20">
                    {genericHelper.getGreekItemDateString(this.props.item, this.props.lang, sharedEnv.hideYearSuffix)}
                  </div>

                  <div className="w-full overflow-auto flex-grow py-2">
                    <SimpleBar autoHide={false} style={{
                      paddingRight: '40px',
                      paddingLeft: '0px',
                      height: '95%',
                      whiteSpace: 'pre-line',
                      wordBreak: 'break-word',
                      fontSize: '1.2rem'
                    }}>
                      {this.props.item.description != null && this.props.item.description[this.props.lang]}
                    </SimpleBar>
                  </div>

                  <div className="category-color-new w-full">
                    <div style={{height: '100%', width: '100%', background: '#000000'}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"h-full w-32 flex items-center " + (this.props.hasNext ? 'cursor-pointer' : '')}
               onClick={this.props.hasNext ? this.props.getNext : null}>
            {this.props.hasNext &&
              <div className="dialog-arrow-new right mx-auto"></div>
            }
          </div>
        </div>
      </Dialog>
    )
      ;
  }
}

export default withMediaFilePreviewDialog(ItemDialog);
