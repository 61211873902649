import React, {Component, createRef} from "react";
import {fileTypeHelper} from "../../helpers/fileTypeHelper";
import "./MediaFilesGrid.css";
import {SortableMediaFilesGrid} from "../SortableMediaFilesGrid/SortableMediaFilesGrid";
import {mediaFileHelper} from "../../helpers/mediaFileHelper";

class MediaFilesGrid extends Component {


  constructor(props) {
    super(props);

    this.state = {
      draggedItem: null,
      fileTypes: fileTypeHelper.fileTypes,
      selectedFileType: fileTypeHelper.fileTypes[0],
      selectedFileTypeValue: fileTypeHelper.fileTypes[0].value,
    };

    this.fileInputRef = createRef();

    this.addFile = this.addFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleFileTypeChange = this.handleFileTypeChange.bind(this);
    this.getMediaFileCountByType = this.getMediaFileCountByType.bind(this);
    this.getMediaListByTypeOrdered = this.getMediaListByTypeOrdered.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.mediaFileList !== this.props.mediaFileList) {
      this.orderMediaFilesByType();
    }
  }

  addFile(event) {
    this.props.onAdd(event);
  }

  deleteFile(mediaFile) {
    this.props.onDelete(mediaFile);
  }

  previewFile(mediaFile) {
    this.props.onPreview(mediaFile);
  }

  handleFileTypeChange(value) {
    const selectedType = this.state.fileTypes.find(fileType => fileType.value === value);
    this.setState({
      selectedFileType: selectedType,
      selectedFileTypeValue: selectedType.value,
    });
  }

  getMediaFileCountByType(mediaType) {
    return this.props.mediaFileList.filter(mediaFile => mediaFile.mediaType === mediaType).length;
  }

  getMediaListByTypeOrdered(mediaType) {
    return mediaFileHelper.getMediaListByTypeOrdered(this.props.mediaFileList, mediaType);
  }

  orderMediaFilesByType() {
    const mediaFileListByType = this.getMediaListByTypeOrdered(this.selectedFileTypeValue);
    mediaFileListByType.forEach((mediaFile, index) => {
      mediaFile.order = index;
    });
  }

  render() {
    const mediaFileListByType = this.getMediaListByTypeOrdered(this.state.selectedFileTypeValue);

    return (
      <div className="p-2 mx-auto sm:m-0">
        <div className="flex rounded-full border-[1px] border-black items-center">
          <div className="py-2">
            <div>
              {this.state.fileTypes.map((fileType) => (
                <button
                  type={"button"}
                  key={fileType.value}
                  onClick={() => this.handleFileTypeChange(fileType.value)}
                  className={`${this.state.selectedFileTypeValue === fileType.value ? "tab-active" : "tab-inactive"}`}
                >
                  {fileType.displayValue} ({this.getMediaFileCountByType(fileType.value)})
                </button>
              ))}
            </div>
          </div>
          <div className="grow"></div>
          <div className="flex-none pr-2">
            <img src={process.env.PUBLIC_URL + "/images/add-icon.png"} alt="add"
                 onClick={() => this.fileInputRef.current.click()}
                 className={`cursor-pointer w-10 h-10 ${this.props.isUploadInProgress ? "opacity-50" : ""}`}
            />
            <input
              ref={this.fileInputRef}
              multiple
              type="file"
              className="hidden"
              onChange={this.addFile}
              accept={this.state.selectedFileType.mimeTypes.join(", ")}
              disabled={this.props.isUploadInProgress}
            />
          </div>
          <div className="w-4"></div>
        </div>

        <SortableMediaFilesGrid
          mediaFileList={mediaFileListByType}
          onDelete={this.deleteFile.bind(this)}
          onPreview={this.previewFile.bind(this)}
          switchMediaFilePlaces={this.props.switchMediaFilePlaces}
        >
        </SortableMediaFilesGrid>

      </div>
    );
  }
}

export default MediaFilesGrid;
