import React, {Component, forwardRef} from 'react';
import sharedEnv from "../../shared/sharedEnv";
import './MediaFileItem.css';
import {mediaFileHelper} from "../../helpers/mediaFileHelper";

class MediaFileItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videoThumbnail: null,
    };
    this.videoRef = React.createRef();
  }

  componentDidMount() {

    if (!mediaFileHelper.isVideo(this.props.mediaFile)) {
      return;
    }

    const video = this.videoRef.current;

    // Wait for the video to load its metadata (e.g., video dimensions)
    video.addEventListener("loadeddata", this.onVideoLoaded);
    video.addEventListener("seeked", this.captureFrame);
  }

  componentWillUnmount() {

    if (!mediaFileHelper.isVideo(this.props.mediaFile)) {
      return;
    }

    const video = this.videoRef.current;

    // Clean up event listeners
    video.removeEventListener("loadeddata", this.onVideoLoaded);
    video.removeEventListener("seeked", this.captureFrame);
  }

  onVideoLoaded = () => {
    const video = this.videoRef.current;
    // Seek to the first frame (0 seconds)
    video.currentTime = 0;
  };

  captureFrame = () => {
    const video = this.videoRef.current;
    const canvas = document.createElement("canvas");

    console.log("videoWidth: " + video.videoWidth);
    console.log("videoHeight: " + video.videoHeight);

    // Set the canvas size to match the video's dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    // Draw the video frame on the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL (base64 image) and update the state
    const imageDataUrl = canvas.toDataURL("image/png");
    this.setState({videoThumbnail: imageDataUrl});
  };

  deleteFile(mediaFile) {
    this.props.onDelete(mediaFile);
  }

  previewFile(mediaFile) {
    this.props.onPreview(mediaFile);
  }

  render() {

    const {mediaFile, url, faded, style, forwardedRef, onDelete, onPreview, ...otherProps} = this.props;

    const inlineStyles = {
      opacity: faded ? '0.2' : '1',
      transformOrigin: '0 0',
      // height: 200,
      // width: 200,
      // gridRowStart: null,
      // gridColumnStart: null,
      // backgroundImage: `url("${url}")`,
      // backgroundSize: 'cover',
      // backgroundPosition: 'center',
      // backgroundColor: 'grey',
      // overflow: 'hidden',
      ...style,
    };

    return (
      <div ref={forwardedRef}
           style={inlineStyles}
           {...otherProps}
           className=" p-2 m">
        <div className="relative example-box">
          {mediaFile.isNew && !mediaFileHelper.isFirstImage(mediaFile) && (
            <div className="absolute top-4 left-4">
              <label className="text-white rounded-xl bg-app-primary-dark px-2 py-1">new</label>
            </div>
          )}

          {mediaFileHelper.isFirstImage(mediaFile) && (
            <div className="absolute top-4 left-4">
              <label className="text-white rounded-xl bg-gray-500 px-2 py-1">primary</label>
            </div>
          )}

          <div className="absolute top-4 right-0">
            {!mediaFile.isNew && (
              <button className="mat-icon-button px-2"
                      onMouseDown={(event) => {
                        event.preventDefault(); // Prevent drag behavior
                      }}
                      onClick={() => {
                        this.previewFile(mediaFile);
                      }}>
                <span className="text-open-lab-700 material-icons">open_in_new</span>
              </button>
            )}
            <button className="mat-icon-button px-2"
                    onMouseDown={(event) => {
                      event.preventDefault(); // Prevent drag behavior
                    }}
                    onClick={() => this.deleteFile(mediaFile)}>
              <span className="text-open-lab-700 material-icons">close</span>
            </button>
          </div>

          <div className="absolute w-full px-2 bottom-3 mx-auto">
            <p className="line-clamp-1 font-bold">{mediaFileHelper.getMediaFileNameFromUrl(mediaFile)}</p>
          </div>

          <div className="image-container">
            {mediaFileHelper.isImage(mediaFile) && (
              <img className="mx-auto creator-image"
                   draggable={false}
                   src={mediaFile.isNew ? mediaFile.imagePreview : sharedEnv.baseURL + '/' + mediaFile.url}
                   alt="media file"/>
            )}
            {mediaFileHelper.isAudio(mediaFile) && (
              <img className="mx-auto creator-image"
                   src={process.env.PUBLIC_URL + "/images/audio_placeholder.png"}
                   alt="audio placeholder"/>
            )}
            {mediaFileHelper.is3DObject(mediaFile) && (
              <img className="mx-auto creator-image"
                   src={process.env.PUBLIC_URL + "/images/media_3d_placeholder.png"}
                   alt="3D object placeholder"/>
            )}
            {mediaFileHelper.isPDF(mediaFile) && (
              <img className="mx-auto creator-image"
                   src={process.env.PUBLIC_URL + "/images/document_placeholder.png"}
                   alt="PDF placeholder"/>
            )}
            {mediaFileHelper.isVideo(mediaFile) && (
              <div className={'w-full h-full flex items-center'}>
                <video
                  crossOrigin="Anonymous"
                  ref={this.videoRef}
                  src={sharedEnv.baseURL + '/' + mediaFile.url} style={{display: "none"}}/>
                {this.state.videoThumbnail ? <img
                    className="mx-auto creator-image"
                    src={this.state.videoThumbnail} alt="First frame of video"/> :
                  <div></div>}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// Wrapping the Photo component with forwardRef to pass ref to it
const ForwardedMediaFileItem = forwardRef((props, ref) => (
  <MediaFileItem {...props} forwardedRef={ref}/>
));

export default ForwardedMediaFileItem;

