import React, {useState} from 'react';
import {arrayMove, rectSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import {closestCenter, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableMediaFileItem} from "../SortableMediaFileItem/SortableMediaFileItem";
import MediaFileItem from "../MediaFileItem/MediaFileItem";

export const SortableMediaFilesGrid = (props) => {

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const [draggingMediaFile, setDraggingMediaFile] = useState(null);


    function handleDragStart(event) {
        console.log("handleDragStart");
        setDraggingMediaFile(props.mediaFileList.find(mediaFile => mediaFile._id === event.active.id));
    }

    function handleDragEnd(event) {
        const {active, over} = event;

        if(!over || !active) {
            return;
        }

        if (active.id !== over.id) {

            const oldIndex = props.mediaFileList.findIndex(mediaFile => mediaFile._id === active.id);
            const newIndex = props.mediaFileList.findIndex(mediaFile => mediaFile._id === over.id);

            const mediaFileActive = props.mediaFileList[oldIndex];
            const mediaFileOver = props.mediaFileList[newIndex];

            props.switchMediaFilePlaces(mediaFileActive, mediaFileOver);

        }

        setDraggingMediaFile(null);
    }

    function handleDragCancel() {
        console.log("handleDragCancel");
        setDraggingMediaFile(null);
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={props.mediaFileList} strategy={rectSortingStrategy}>
                <div className="w-full flex flex-wrap pt-8 min-h-[300px]">
                    {props.mediaFileList.map((mediaFile, index) => (
                        <SortableMediaFileItem
                            style={{opacity: draggingMediaFile && mediaFile._id === draggingMediaFile._id ? 0.2 : 1}}
                            mediaFile={mediaFile}
                            key={mediaFile._id || mediaFile.url || mediaFile.file}
                            index={index}
                            onDelete={props.onDelete}
                            onPreview={props.onPreview}
                        />
                    ))}
                </div>
            </SortableContext>

            <DragOverlay adjustScale={true}>
                {draggingMediaFile ? (
                    <MediaFileItem mediaFile={draggingMediaFile}
                                   index={props.mediaFileList.indexOf(draggingMediaFile)}/>
                ) : null}
            </DragOverlay>
        </DndContext>

    );
};
