import MediaTypeEnum from "../enums/media-type-enum";
import sharedEnv from "../shared/sharedEnv";

class MediaFileHelper {
  getMediaListByTypeOrdered(mediaFileList, mediaType) {
    return mediaFileList
      .filter(mediaFile => mediaFile.mediaType === mediaType)
      .sort((a, b) => a.order - b.order);
  }


  isImage(mediaFile) {
    return mediaFile.mediaType === MediaTypeEnum.imageMedia;
  }

  isVideo(mediaFile) {
    return mediaFile.mediaType === MediaTypeEnum.videoMedia;
  }

  isAudio(mediaFile) {
    return mediaFile.mediaType === MediaTypeEnum.audioMedia;
  }

  is3DObject(mediaFile) {
    return mediaFile.mediaType === MediaTypeEnum.media3D;
  }

  isPDF(mediaFile) {
    return mediaFile.mediaType === MediaTypeEnum.pdfMedia;
  }

  isFirstImage(mediaFile) {
    return mediaFile.order === 0 && mediaFile.mediaType === MediaTypeEnum.imageMedia;
  }

  getMediaFileNameFromUrl(mediaFile) {
    return mediaFile.isNew ? mediaFile.file.name : mediaFile.url.split('/').pop();
  }

  previewMediaFile(mediaFile) {
    window.open(sharedEnv.baseURL + '/' + mediaFile.url, "_blank");
  }

  getImageSrcForMediaFileType(mediaFile) {
    switch (mediaFile.mediaType) {
      case MediaTypeEnum.imageMedia:
        return mediaFile.isNew ? mediaFile.imagePreview : sharedEnv.baseURL + '/' + mediaFile.url;
      case MediaTypeEnum.videoMedia:
        return process.env.PUBLIC_URL + "/images/video_placeholder.png";
      case MediaTypeEnum.audioMedia:
        return process.env.PUBLIC_URL + "/images/audio_placeholder.png";
      case MediaTypeEnum.media3D:
        return process.env.PUBLIC_URL + "/images/media_3d_placeholder.png";
      case MediaTypeEnum.pdfMedia:
        return process.env.PUBLIC_URL + "/images/document_placeholder.png";
      default:
        return process.env.PUBLIC_URL + "/images/image_placeholder.png";
    }
  }

  getMediaFileUrl(mediaFile) {
    return mediaFile.isNew ? mediaFile.imagePreview : sharedEnv.baseURL + '/' + mediaFile.url;
  }

  getCarouselMediaFileList(mediaFileList) {

    if (!mediaFileList || mediaFileList.length === 0) {
      return [];
    }

    const imageMediaFileList = this.getMediaListByTypeOrdered(mediaFileList, MediaTypeEnum.imageMedia);
    const videoMediaFileList = this.getMediaListByTypeOrdered(mediaFileList, MediaTypeEnum.videoMedia);
    const audioMediaFileList = this.getMediaListByTypeOrdered(mediaFileList, MediaTypeEnum.audioMedia);
    const pdfMediaFileList = this.getMediaListByTypeOrdered(mediaFileList, MediaTypeEnum.pdfMedia);
    const media3DFileList = this.getMediaListByTypeOrdered(mediaFileList, MediaTypeEnum.media3D);

    return imageMediaFileList.concat(videoMediaFileList, audioMediaFileList, pdfMediaFileList, media3DFileList);
  }

}


export const mediaFileHelper = new MediaFileHelper();
