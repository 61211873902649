class ItemMediaFile {
  _id = '';
  item = '';
  isNew = true;
  mediaType = '';
  file = null;
  imagePreview = '';
  order = 0;
}

module.exports = ItemMediaFile;
