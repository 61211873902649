import React, {Component} from 'react';
import './MediaFileCarouselItem.css';
import {mediaFileHelper} from "../../helpers/mediaFileHelper";

class MediaFileCarouselItem extends Component {


  render() {

    const {mediaFile} = this.props;

    return (
      <div className="w-full h-full">
        <img className="mx-auto object-contain h-full w-full" src={mediaFileHelper.getImageSrcForMediaFileType(mediaFile)}/>
      </div>
    );
  }
}

export default MediaFileCarouselItem;

