import React, {Component} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import IconButton from "@mui/material/IconButton";
import {ChevronLeft, ChevronRight, ZoomIn, ZoomInOutlined, ZoomOut} from "@mui/icons-material";
import {Typography} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


class PdfViewer extends Component {

  constructor() {
    super();
    this.state = {
      pdfNumberOfPages: 0,
      pageNumber: 1,
      scale: 1,
    }
    this.containerRef = React.createRef();
  }


  onDocumentLoadSuccess = ({numPages}) => {
    this.setState({
      pdfNumberOfPages: numPages
    });
    // this.initPageZoom();
  };

  zoomIn = () => {
    this.setState({scale: this.state.scale + 0.1});
  }

  zoomOut = () => {
    this.setState({scale: this.state.scale - 0.1});
  }

  resetZoom = () => {
    this.setState({scale: 1});
  }

  initPageZoom = () => {
    let aspectRatio = this.containerRef.current?.clientWidth / this.containerRef.current?.clientHeight;
    this.setState({scale: aspectRatio});
  }


  render() {

    const {file, showZoom} = this.props;

    if (!file) {
      return null;
    }

    const {pageNumber, pdfNumberOfPages} = this.state;

    return (
      <div className={"mx-auto h-full w-full overflow-auto"} ref={this.containerRef}>
        <Document
          file={file}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber}
                scale={this.state.scale}
                height={this.containerRef.current?.clientHeight ?? 150}
                width={this.containerRef.current?.clientWidth ?? 150}
          />
        </Document>

        {showZoom &&
          <div className={'flex z-20 absolute bottom-4 w-20 right-4'}>
            <div
              className="mx-auto flex flex-wrap justify-center items-center bg-gray-300 bg-opacity-90 py-1 px-2 rounded-2xl">

              <IconButton
                onClick={() => this.zoomIn()}
                aria-label="zoom in">
                <ZoomIn/>
              </IconButton>

              <IconButton
                onClick={() => this.zoomOut()}
                aria-label="zoom out">
                <ZoomOut/>
              </IconButton>
            </div>
          </div>
        }
        <div className={'flex z-10 absolute bottom-4 w-full'}>
          <div
            className="mx-auto flex justify-center items-center bg-gray-300 bg-opacity-90 py-1 px-2">
            <IconButton
              onClick={() => this.setPageNumber(pageNumber - 1)}
              disabled={pageNumber <= 1}
              aria-label="previous page"
              style={{opacity: pageNumber <= 1 ? 0.5 : 1}}
            >
              <ChevronLeft/>
            </IconButton>
            <div className={'text-sm'}>
              {`Page ${pageNumber} of ${pdfNumberOfPages}`}
            </div>
            <IconButton
              onClick={() => this.setPageNumber(pageNumber + 1)}
              disabled={pageNumber >= pdfNumberOfPages}
              aria-label="next page"
              style={{opacity: pageNumber >= pdfNumberOfPages ? 0.5 : 1}}
            >
              <ChevronRight/>
            </IconButton>
          </div>
        </div>
      </div>

    );
  }

  setPageNumber(number) {
    this.setState({pageNumber: number});
    this.resetZoom();
  }
}

export default PdfViewer;

