import React, {Component} from 'react';
import './MediaFilePreview.css';
import {mediaFileHelper} from "../../helpers/mediaFileHelper";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import PdfViewer from "../PdfViewer/PdfViewer";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

class MediaFilePreview extends Component {

  state = {
    isFadingOut: false,
    isFadingIn: false,
    currentMediaFile: this.props.mediaFile,  // Set initial media file
    nextMediaFile: null,  // For new media file
    hasPanZoom: this.props.hasPanZoom,
  };

  timeoutId = null; // Store the timeout ID

  static getDerivedStateFromProps(nextProps, prevState) {
    // If the media file changes, trigger fade out
    if (nextProps.mediaFile !== prevState.currentMediaFile) {

      if (prevState.currentMediaFile && nextProps.mediaFile) {
        if (prevState.currentMediaFile.url === nextProps.mediaFile.url) {
          return null;
        }
      }

      return {
        isFadingOut: true,
        isFadingIn: false,
        nextMediaFile: nextProps.mediaFile,  // Store the new media file to switch later
      };
    }
    return null;
  }


  componentDidUpdate(prevProps, prevState) {
    // Handle the fade out state
    if (this.state.nextMediaFile) {
      // Clear any existing timeout
      clearTimeout(this.timeoutId);
      // Set a timeout to switch to the next media file
      this.timeoutId = setTimeout(() => {
        this.handleFadeInOrOutEnd();
      }, 1000); // 1 second timeout
    }
  }

  handleFadeInOrOutEnd = () => {
    // console.log('Timeout called Fade in or out ended');
    if (this.state.isFadingOut || this.state.isFadingIn) {
      this.setState({
        isFadingOut: false,
        isFadingIn: false,
        currentMediaFile: this.state.nextMediaFile,  // Now show the new media file
        nextMediaFile: null,  // Clear nextMediaFile
      });
    }
    this.timeoutId = null;
  }

  handleFadeOutEnd = () => {
    // console.log('Fade out ended');
    // When fade-out ends, switch to the new media file and fade in
    this.setState({
      isFadingOut: false,
      isFadingIn: true,
      currentMediaFile: this.state.nextMediaFile,  // Now show the new media file
      nextMediaFile: null,  // Clear nextMediaFile
    });
  };

  handleFadeInEnd = () => {
    // console.log('Fade in ended');
    // When fade-in ends, reset the state
    this.setState({isFadingIn: false});
  };

  render() {
    const {isFadingOut, isFadingIn, currentMediaFile, hasPanZoom} = this.state;

    if (!currentMediaFile) {
      return null;
    }

    return (
      <div
        className={`media-preview-container mx-auto flex h-full items-center w-full min-w-[200px] ${
          isFadingOut ? 'fade-out' : isFadingIn ? 'fade-in' : ''
        }`}
        onTransitionEnd={isFadingOut ? this.handleFadeOutEnd : this.handleFadeInEnd}
      >
        {/* Render the current media file */}
        {mediaFileHelper.isImage(currentMediaFile) && !hasPanZoom && (
          <img
            className="w-full h-full mx-auto object-contain"
            src={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
            alt="Media Preview"
          />
        )}

        {mediaFileHelper.isImage(currentMediaFile) && hasPanZoom && (
          <TransformWrapper
            centerOnInit={true}>
            <TransformComponent
              wrapperStyle={{width: '100%', height: '100%'}}
              contentStyle={{width: "100%", height: "100%"}}>
              <img
                className="w-full h-full object-contain"
                src={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
                alt="Media Preview"
              />
            </TransformComponent>
          </TransformWrapper>
        )}

        {mediaFileHelper.isVideo(currentMediaFile) && (
          <video
            preload="metadata"
            className="w-full h-full mx-auto"
            src={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
            controls
          />
        )}
        {mediaFileHelper.isAudio(currentMediaFile) && (
          <audio
            preload="metadata"
            className="w-full h-44 mx-auto max-w-2xl"
            src={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
            controls
          />
        )}
        {mediaFileHelper.isPDF(currentMediaFile) && (
          <PdfViewer
            file={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
            className="w-full h-full mx-auto"
            showZoom={hasPanZoom}
          />
        )}
        {mediaFileHelper.is3DObject(currentMediaFile) && (
          <model-viewer
            style={{height: '100%', width: '100%'}}
            src={mediaFileHelper.getMediaFileUrl(currentMediaFile)}
            shadow-intensity="1"
            camera-controls
            touch-action="pan-y"
          ></model-viewer>
        )}
      </div>
    );
  }

}

export default MediaFilePreview;

