import React, {Component, createRef} from "react";
import "./MediaFileCarousel.css";
import {mediaFileHelper} from "../../helpers/mediaFileHelper";
import MediaFileCarouselItem from "../MediaFileCarouselItem/MediaFileCarouselItem";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

class MediaFileCarousel extends Component {


  constructor(props) {
    super(props);

    this.state = {
      selectedItemIndex: 0,
      mediaFileList: this.props.mediaFileList,
      mediaFileListChanged: false,
    };

    this.handleFileTypeChange = this.handleFileTypeChange.bind(this);
    this.getMediaFileCountByType = this.getMediaFileCountByType.bind(this);
    this.getMediaListByTypeOrdered = this.getMediaListByTypeOrdered.bind(this);

    this.carouselRef = createRef();
    this.carouselItemRefs = [];

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mediaFileList !== prevState.mediaFileList) {
      return {
        mediaFileList: nextProps.mediaFileList,
        selectedItemIndex: 0,
        mediaFileListChanged: true,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle the fade out state
    if (this.state.mediaFileListChanged) {
      this.setState({mediaFileListChanged: false});
      setTimeout(() => {
        this.selectItem(this.state.selectedItemIndex);
      }, 200);
    }
  }

  handleFileTypeChange(value) {
    const selectedType = this.state.fileTypes.find(fileType => fileType.value === value);
    this.setState({
      selectedFileType: selectedType,
      selectedFileTypeValue: selectedType.value,
    });
  }

  getMediaFileCountByType(mediaType) {
    return this.props.mediaFileList.filter(mediaFile => mediaFile.mediaType === mediaType).length;
  }

  getMediaListByTypeOrdered(mediaFileList, mediaType) {
    return mediaFileHelper.getMediaListByTypeOrdered(mediaFileList, mediaType);
  }

  goNext = () => {
    this.setState(
      (prevState) => ({
        selectedItemIndex:
          prevState.selectedItemIndex === this.props.mediaFileList.length - 1
            ? 0
            : prevState.selectedItemIndex + 1,
      }),
      () => this.selectItem(this.state.selectedItemIndex)
    );
  };

  goPrev = () => {
    this.setState(
      (prevState) => ({
        selectedItemIndex:
          prevState.selectedItemIndex === 0
            ? this.props.mediaFileList.length - 1
            : prevState.selectedItemIndex - 1,
      }),
      () => this.selectItem(this.state.selectedItemIndex)
    );
  };

  selectItem = (index, emitSelectedEvent = true) => {
    this.setState({selectedItemIndex: index});
    const item = this.carouselItemRefs[index];
    const carousel = this.carouselRef.current;
    if (carousel && item) {
      carousel.scrollLeft =
        item.offsetLeft - carousel.offsetWidth / 2 + item.offsetWidth / 2;
    }
    if (emitSelectedEvent && this.props.onItemSelected) {
      this.props.onItemSelected(this.props.mediaFileList[index]);
    }
  };

  onResize = () => {
    this.selectItem(this.state.selectedItemIndex, false);
  };

  getItemHeight = () => {
    return 100;
  };

  getClasses = (i) => {
    if (!this.props.scaleItems) {
      return 'scale-100';
    }
    if (i === this.state.selectedItemIndex) {
      return 'scale-100';
    } else if (
      i === this.state.selectedItemIndex + 1 ||
      i === this.state.selectedItemIndex - 1
    ) {
      return 'scale-75';
    } else {
      return 'scale-50';
    }
  };

  render() {
    const mediaFileList = this.state.mediaFileList;

    return (
      <div
        className="flex items-center justify-center w-full h-full py-0 sm:py-1"
        data-test="carousel-view"
      >
        <div className="w-full relative flex items-center justify-center">
          <div
            className="absolute z-20 left-0 bg-white bg-opacity-60 cursor-pointer flex items-center h-full w-16"
            data-test="carousel-previous"
            onClick={this.goPrev}
          >
            <ChevronLeft className={'scale-150 mx-auto'}/>
          </div>
          <div
            ref={this.carouselRef}
            className="w-full h-full mx-auto overflow-x-scroll sm:overflow-x-hidden overflow-y-hidden scrollbar-hide scroll-smooth"
          >
            <div
              className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition-all ease-out duration-700 select-none">
              <div className="h-1 flex-shrink-0 relative w-1/2"></div>
              {mediaFileList.map((item, i) => (
                <div
                  key={i}
                  ref={(el) => (this.carouselItemRefs[i] = el)}
                  data-test={
                    this.state.selectedItemIndex === i
                      ? 'carousel-item-selected'
                      : 'carousel-item'
                  }
                  data-index={i}
                  onClick={() => this.selectItem(i)}
                  className={`mx-auto flex flex-shrink-0 relative w-auto transition-all ease-out duration-500 ${this.getClasses(
                    i
                  )}`}
                  style={{height: `${this.getItemHeight()}px`}}
                >
                  <MediaFileCarouselItem
                    mediaFile={item}
                  />
                </div>
              ))}
              <div className="h-1 flex-shrink-0 relative w-1/2"></div>
            </div>
          </div>
          <div
            className="absolute z-20 right-0 bg-white bg-opacity-60 cursor-pointer flex items-center h-full w-16"
            data-test="carousel-next"
            onClick={this.goNext}
          >
            <ChevronRight className={'scale-150 mx-auto'}/>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaFileCarousel;
