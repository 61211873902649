import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import MediaFileItem from "../MediaFileItem/MediaFileItem";
import ForwardedMediaFileItem from "../MediaFileItem/MediaFileItem";

export const SortableMediaFileItem = (props) => {
  const sortable = useSortable({id: props.mediaFile._id || props.mediaFile.url});
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ForwardedMediaFileItem
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};
