import React, {Component} from 'react';
import './UserAddEditForm.css';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {localStorageHelper} from "../../../helpers/localStorageHelper";
import {withRouter} from "../../../hooks/withRouter";
import {TransitionDown} from "../../Transition/Transition";
import {apiService} from "../../../services/apiService";
import User from "../../../models/user";
import SnackbarTypeEnum from "../../../enums/sanckbarTypeEnum";
import {withSnackbar} from "../../../providers/SnackbarProvider";


function getCleanState() {
  return {
    initialStartDate: null,
    initialEndDate: null,
    user: {
      email: {
        value: '',
        valid: false,
        touched: false
      },
      password: {
        value: '',
        valid: false,
        touched: false
      },
      password2: {
        value: '',
        valid: false,
        touched: false
      },
    },
    formIsValid: false,
    loading: false,
    hasError: false,
    errorCode: 0,
    displayColorPicker: false
  };
}

class UserAddEditForm extends Component {

  constructor(props) {
    super(props);
    this.state = getCleanState();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user === prevProps.user || this.props.user === '') {
      return;
    }
    try {
      const user = await apiService.getUser(this.props.user);

      let newUser = {
        email: {
          value: user.email,
          valid: true,
          touched: false
        },
        password: {
          value: '',
          valid: false,
          touched: false
        },
        password2: {
          value: '',
          valid: false,
          touched: false
        },
      };

      this.setState({
        user: newUser
      }, () => {
        this.checkFormValidity();
      });
    } catch (error) {
      console.log(error)
    }
  }

  checkFormValidity() {
    let formIsValid = true;
    for (let userElement in this.state.user) {
      formIsValid = formIsValid && this.state.user[userElement].valid;
    }

    // password match check
    formIsValid = formIsValid && this.state.user.password.value === this.state.user.password2.value;

    this.setState({
      formIsValid: formIsValid
    });
  }

  isFormTouched() {
    let formIsTouched = false;
    for (let userElement in this.state.user) {
      formIsTouched = formIsTouched || this.state.user[userElement].touched;
    }
    return formIsTouched;
  }

  handleChange = (element) => event => {
    let updatedUser = {...this.state.user};
    updatedUser[element].touched = true;

    if (element.indexOf('email') > -1) {
      updatedUser[element].value = event.target.value;
      // email validation
      updatedUser[element].valid = updatedUser[element].value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null;
    } else if (element.indexOf('password') > -1) {
      updatedUser[element].value = event.target.value;
      updatedUser[element].valid = updatedUser[element].value.length >= 6;
    } else if (element.indexOf('password2') > -1) {
      updatedUser[element].value = event.target.value;
      updatedUser[element].valid = updatedUser[element].value === this.state.user.password.value;
    }


    this.setState({
      user: updatedUser
    }, () => {
      this.checkFormValidity();
    });
  }

  submitForm = async (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const user = new User();

    if (this.props.isEdit) {
      user._id = this.props.user;
    }

    user.email = this.state.user.email.value;
    user.password = this.state.user.password.value;

    try {
      if (this.props.isEdit) {
        await apiService.updateUser(user)
      } else {
        await apiService.createUser(user)
      }

      this.props.showSnackbar({
        message: 'Επιτυχής αποθήκευση!',
        snackbarType: SnackbarTypeEnum.SUCCESS
      });

      this.handleClose();
    } catch (error) {

      this.props.showSnackbar({
        message: 'Κάτι πήγε στραβά, προσπαθήστε ξανά!',
        snackbarType: SnackbarTypeEnum.ERROR
      });

      this.setState({
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorageHelper.clear();
            this.props.navigate('/login');
          } else {
            this.handleClose();
          }
        }, 3000);
      });
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    this.setState(getCleanState(), () => {
      this.props.handleClose();
    });
  };

  toggleDisplayColorPicker = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }


  render() {

    return (
      <Dialog
        data-test={this.props.isOpen ? "user-add-edit-form-dialog" : ""}
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={TransitionDown}
        keepMounted
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{paper: 'AdminDialogPaper'}}
      >
        <DialogContent>
          <div className="AdminDialogClose" onClick={this.handleClose}>
            <CloseIcon style={{fontSize: 30, color: '#595959'}}/>
          </div>

          <form className="user-form" onSubmit={this.submitForm}>
            <div className="UserFormField pt-10">
              <div className="UserFormFieldName">Email</div>
              <FormControl className="TextField">
                <TextField
                  data-test="user-email"
                  label="Email"
                  value={this.state.user.email.value}
                  error={this.state.user.email.touched && !this.state.user.email.valid}
                  onChange={this.handleChange('email')}
                  margin="none"
                  required={true}
                />
              </FormControl>
            </div>

            <div className="UserFormField">
              <div className="UserFormFieldName">Κωδικός</div>
              <FormControl className="TextField">
                <TextField
                  data-test="user-password"
                  label="Κωδικός"
                  value={this.state.user.password.value}
                  error={this.state.user.password.touched && !this.state.user.password.valid}
                  onChange={this.handleChange('password')}
                  margin="none"
                  type="password"
                  required={true}
                />
              </FormControl>
            </div>

            <div className="UserFormField">
              <div className="UserFormFieldName">Επιβεβαίωση κωδικού</div>
              <FormControl className="TextField">
                <TextField
                  data-test="user-password2"
                  label="Επιβεβαίωση κωδικού"
                  value={this.state.user.password2.value}
                  error={this.state.user.password2.touched && !this.state.user.password2.valid}
                  onChange={this.handleChange('password2')}
                  margin="none"
                  type="password"
                  required={true}
                />
              </FormControl>
            </div>

            <div className="Wrapper pt-4">
              <Button
                data-test="user-save-button"
                type="submit"
                variant="contained"
                color="primary"
                className="NoRadiusButton"
                disabled={this.state.loading || !this.state.formIsValid}
              >
                Αποθηκευση
              </Button>
              {this.state.loading &&
                <CircularProgress size={24}
                                  color="primary"
                                  className="ButtonProgress"
                />
              }
            </div>
            {this.state.hasError && (
              this.state.errorCode === 401 ?
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
                :
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
            )}
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withSnackbar(withRouter(UserAddEditForm));
