import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material"
import React, {createContext, useContext, useState} from "react"
import {TransitionDown} from "../Controls/Transition/Transition";
import CloseIcon from "@mui/icons-material/Close";
import MediaFilePreview from "../Controls/MediaFilePreview/MediaFilePreview";

// Create the context so we can use it in our App
const DialogContext = createContext(() => {
  throw new Error("Component is not wrapped with a DialogProvider.")
})

const MediaFilePreviewDialogProvider = ({children}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState({
    mediaFile: null,
  })
  const [promiseInfo, setPromiseInfo] = useState()
  const showMediaFilePreviewDialog = options => {
    // When the dialog is shown, keep the promise info so we can resolve later
    return new Promise((resolve, reject) => {
      setPromiseInfo({resolve, reject})
      setOptions(options)
      setOpen(true)
    })
  }
  const handleClose = () => {
    // if the dialog gets canceled, resolve with `false`
    setOpen(false)
    promiseInfo?.resolve(false)
    setPromiseInfo(undefined)
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullWidth={false} fullScreen={false}
              TransitionComponent={TransitionDown}>
        <DialogContent>
          <div className="z-30 absolute top-3 right-3 cursor-pointer bg-white p-3" onClick={handleClose}>
            <img style={{width: '80%'}} alt="close" src={process.env.PUBLIC_URL + "/images/close-dialog.svg"}/>
          </div>

          <div className={'relative h-[90vh] w-[90vw] max-w-[100%] max-h-[100%] flex items-center'}>
            <div className="relative flex h-full w-full items-center">
              <MediaFilePreview
                className="w-full h-full"
                mediaFile={options.mediaFile}
                hasPanZoom={true}
              >
              </MediaFilePreview>
            </div>
          </div>
        </DialogContent>

      </Dialog>
      <DialogContext.Provider value={showMediaFilePreviewDialog}>
        {children}
      </DialogContext.Provider>
    </>
  )
}

export const withMediaFilePreviewDialog = Component => props => {
  const showMediaFilePreviewDialog = useMediaFilePreviewDialog()
  return <Component {...props} showMediaFilePreviewDialog={showMediaFilePreviewDialog}/>
}

// By calling `useMediaFilePreviewDialog()` in a component we will be able to use the `showDialog()` function
export const useMediaFilePreviewDialog = () => {
  return useContext(DialogContext)
}

export default MediaFilePreviewDialogProvider
