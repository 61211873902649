import React, {Component} from 'react';
import './UsersTable.css';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";

class UsersTable extends Component {

  headers = [
    {id: 'email', label: 'Email'},
  ];

  state = {
    order: 'asc',
    orderBy: 'email',
    page: 0,
    rowsPerPage: 20,
  };


  render() {
    const users = this.props.users;
    const openUserEditDialog = this.props.openUserEditDialog;
    const openUserDeleteDialog = this.props.openUserDeleteDialog;

    return (
      <Table className="UsersTable" data-test={'users-table'}>
        <TableHead>
          <TableRow>
            <TableCell className="AdminHead miniCol"></TableCell>
            <TableCell className="AdminHead mediumCol">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow
              data-test={'user-row'}
              className="AdminUserRow"
              hover
              tabIndex={-1}
              key={user._id}
            >
              <TableCell className="miniCol">

                <div className={'flex'}>
                  <IconButton className="IconButton"
                              data-test={'user-delete-button'}
                              aria-label="Delete"
                              onClick={event => openUserDeleteDialog(user._id)}
                              style={{marginTop: '-3px'}} // marginBottom: '10%',
                  >
                    <img alt="delete" style={{maxWidth: '22px'}}
                         src={process.env.PUBLIC_URL + "/images/delete.svg"}/>
                  </IconButton>
                  <IconButton className="IconButton"
                              data-test={'user-edit-button'}
                              aria-label="Edit"
                              onClick={event => openUserEditDialog(user._id)}
                  >
                    <img alt="delete" style={{maxWidth: '20px'}}
                         src={process.env.PUBLIC_URL + "/images/edit.svg"}/>
                  </IconButton>
                </div>
              </TableCell>
              <TableCell className="mediumCol title-cell">{user.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default UsersTable;
